import Vue from 'vue'
import { notifyError } from '@/utils/notify'
import EventBus from '@/EventBus'
import VueRouter from 'vue-router'

function checkIfNeedAction (message, status) {
  if (
    (status === 401) ||
    (message?.toLowerCase() === 'unauthorized') ||
    (message?.toLowerCase() === 'deprecated token')
  ) EventBus.emit('action:logout')

  if (status === 403) {
    EventBus.emit('action:404')
  }
}

Vue.config.errorHandler = (err, vm, info) => {
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.

  console.info('Vue.config.errorHandler')
  console.error(err, vm, info)
  notifyError(err.message)

  checkIfNeedAction(err.message, err.response?.status, err)
}

window.onerror = function (message, source, lineno, colno, error) {
  console.info('window.onerror')
  console.error(error)

  notifyError(message)

  checkIfNeedAction(message, null, error)
}

window.addEventListener('unhandledrejection', function (event) {
  // the event object has two special properties:
  // event.promise // [object Promise] - the promise that generated the error
  // event.reason // Error: Whoops! - the unhandled error object
  console.info('unhandledrejection')
  console.info(event)
  console.error({ reason: event.reason })

  /* TODO: костыль чтобы не писать декоратор в для VueRouter guards, смотри TODO в src/router/index.js */
  if (!VueRouter.isNavigationFailure(event.reason, VueRouter.NavigationFailureType.redirected)) {
    notifyError(event.reason?.message, event.reason?.body?.i18nArgs)
  }

  checkIfNeedAction(event.reason?.message, +event.reason?.response?.status, event.reason)
})
