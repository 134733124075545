import axios from 'axios'
import * as authService from '@/services/auth'

/**
 * Сконфигурированный axios клиент
 * */

const axiosClient = axios.create({
  timeout: 100000,
  baseURL: process.env.VUE_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  maxContentLength: 10000
})

/**
 * Добавление JSON TOKEN в каждый запрос
 * */
axiosClient.interceptors.request.use(
  config => {
    const authenticationToken = authService.getAccess().accessToken
    if (authenticationToken) {
      config.headers.Authorization = authenticationToken
    }
    return config
  },
  error => Promise.reject(error)
)

export default axiosClient
