var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-tour" },
    [
      _vm._t(
        "default",
        [
          _vm.steps[_vm.currentStep]
            ? _c("v-step", {
                key: _vm.currentStep,
                attrs: {
                  direction: _vm.direction,
                  step: _vm.steps[_vm.currentStep],
                  "previous-step": _vm.previousStep,
                  "next-step": _vm.nextStep,
                  stop: _vm.stop,
                  skip: _vm.skip,
                  finish: _vm.finish,
                  "is-first": _vm.isFirst,
                  "is-last": _vm.isLast,
                  labels: _vm.customOptions.labels,
                  "enabled-buttons": _vm.customOptions.enabledButtons,
                  highlight: _vm.customOptions.highlight,
                  "stop-on-fail": _vm.customOptions.stopOnTargetNotFound,
                  debug: _vm.customOptions.debug
                },
                on: {
                  targetNotFound: function($event) {
                    return _vm.$emit("targetNotFound", $event)
                  }
                }
              })
            : _vm._e()
        ],
        {
          currentStep: _vm.currentStep,
          steps: _vm.steps,
          previousStep: _vm.previousStep,
          nextStep: _vm.nextStep,
          stop: _vm.stop,
          skip: _vm.skip,
          finish: _vm.finish,
          isFirst: _vm.isFirst,
          isLast: _vm.isLast,
          labels: _vm.customOptions.labels,
          enabledButtons: _vm.customOptions.enabledButtons,
          highlight: _vm.customOptions.highlight,
          debug: _vm.customOptions.debug
        }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }