
































import Vue from 'vue'
import EventBus from '@/EventBus'
import { debounce } from 'lodash'
import { getLinkByEvent } from '@/utils/mediaHelpers'
import store from './store'
import { mapActions } from 'vuex'

const ANIMATION_LIFE_TIME = 3000

function randomInteger (min: number, max: number) {
  // случайное число от min до (max+1)
  const rand = min + Math.random() * (max + 1 - min)
  return Math.floor(rand)
}

interface CentrifugeEmojiPayload {
  data: {
    event: 'Send emoji'
    payload: {
      emoji_name: string
    }
  }
}

export default Vue.extend({
  store,

  data () {
    return {
      opened: false,
      emotions: ['hands', 'heart', 'finger-up'],
      debouncedEmotionEmit: (() => { /* */ }) as ((emotionName: string) => void)
    }
  },

  created () {
    this.handleGetEmotion = this.handleGetEmotion.bind(this)

    this.debouncedEmotionEmit = debounce((emotionName: string) => {
      this.sendEmoji(emotionName)
    }, 2000, { leading: false })

    // eslint-disable-next-line @typescript-eslint/unbound-method
    EventBus.subscribe('ws:chat_service', this.handleGetEmotion)
  },

  beforeDestroy () {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    EventBus.unsubscribe('ws:chat_service', this.handleGetEmotion)
  },

  methods: {
    ...mapActions(['sendEmoji']),

    getLinkByEvent,

    togglePanel () {
      this.opened = !this.opened
    },

    onEmotionClick (emotionName: string) {
      if (!this.opened) return
      this.debouncedEmotionEmit(emotionName)
      this.createEmotion(emotionName, true)
    },

    handleGetEmotion (payload: CentrifugeEmojiPayload) {
      if (!this.opened) return

      if (payload.data.event.toLowerCase() === 'send emoji') {
        if (!this.emotions.includes(payload.data.payload.emoji_name) || !this.opened) return

        const randomEmojis = Array(randomInteger(1, 3)).fill(null).map(() => this.emotions[randomInteger(0, 2)])

        this.createEmotion(payload.data.payload.emoji_name)
        let offset = 0

        randomEmojis.forEach(el => {
          const delay = offset + randomInteger(300, 700)
          offset = delay
          setTimeout(() => {
            this.createEmotion(el)
          }, delay)
        })
      }
    },

    createEmotion (emotionName: string, self = false) {
      // x animation block
      const emotionX = document.createElement('div')
      emotionX.className = 'emotion-animation'
      const randomStartDelay = -Math.random() + 's'
      emotionX.style.animationDelay = randomStartDelay

      // y animation block
      const emotionY = document.createElement('div')
      emotionY.className = 'emotion-animation-y'

      // rotation animation block
      const emotionRotate = document.createElement('img')
      emotionRotate.src = require(`@/assets/${this.emotionImgPath(emotionName, self)}`)
      emotionRotate.style.animationDelay = randomStartDelay

      // emotionRotate.onerror = () => {
      //   emotionRotate.src = require(`@/assets/${this.emotionImgPath(emotionName, self)}`)
      // }

      emotionY.appendChild(emotionRotate)
      emotionX.appendChild(emotionY)

      /* eslint-disable-next-line */
      const emotionAreaElements = this.$refs['emotion-area-' + emotionName] as HTMLElement[]

      if (emotionAreaElements[0]) {
        emotionAreaElements[0].appendChild(emotionX)
      }

      setTimeout(() => {
        if (emotionX) {
          emotionX.remove()
        }
      }, ANIMATION_LIFE_TIME)
    },

    emotionImgPath (emotionName: string, self: boolean) {
      return `img/svg/emotion-${emotionName}${self ? '-self' : ''}.svg`
    }
  }
})
