var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "player-emotion", class: { opened: _vm.opened } },
    [
      _c("div", { staticClass: "player-emotion__panel panel" }, [
        _c(
          "div",
          { staticClass: "panel__emotions" },
          _vm._l(_vm.emotions, function(emotion) {
            return _c(
              "div",
              { key: emotion, staticClass: "panel__emotion" },
              [
                _c("v-fade-transition", [
                  _vm.opened
                    ? _c("div", {
                        ref: "emotion-area-" + emotion,
                        refInFor: true,
                        staticClass: "player-emotion__area"
                      })
                    : _vm._e()
                ]),
                _c("img", {
                  staticClass: "panel__emotion-icon",
                  attrs: {
                    src: require("@/assets/" + _vm.emotionImgPath(emotion)),
                    alt: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.onEmotionClick(emotion)
                    }
                  }
                })
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "panel__arrow", on: { click: _vm.togglePanel } },
          [_c("div", { staticClass: "panel__arrow-inner" })]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }