const serverErrors = {
  'invalid password or username': '@:message.errors.invalidLogin',
  invalidLogin: 'Nieprawidłowe hasło lub nazwa użytkownika',
  'internal server error': 'Wewnętrzny błąd serwera',
  'not found error': 'Nie znaleziono'
}

const validationErrors = {
  NOT_UNIQUE: 'Pole o tej samej wartości już istnieje',
  REQUIRED: 'Pole wymagane',
  EMAIL: 'Nieprawidłowy E-mail',
  PHONE: 'Nieprawidłowy telefon',
  UNIVERSAL_INVALID_FIELD: 'Nieprawidłowa wartość pola',
  CYRILLIC_AND_SPACE: 'Tylko cyrylica i spacje',
  DIGIT: 'Tylko cyfry',
  ALPHANUMERIC: 'Tylko cyfry i litery',
  RANGE_MIN: 'Pole musi zawierać co najmniej znaki {min}',
  RANGE_MAX: 'Pole nie może zawierać więcej niż {max} znaków',
  RANGE_MIN_MAX: 'Pole musi zawierać od {min} do {max} znaków',
  RANGE_EXACT: 'Pole musi zawierać znaki: {count}',
  LETTERS_RANGE_MIN: 'Pole musi zawierać co najmniej litery {min}',
  LETTERS_RANGE_MAX: 'Pole nie może zawierać więcej niż {max} liter',
  LETTERS_RANGE_MIN_MAX: 'Pole musi zawierać od {min} do {max} liter',
  LETTERS_RANGE_EXACT: 'Pole musi zawierać litery: {liczyć}',
  FILE_SIZE_MAX: 'Rozmiar pliku powinien być mniejszy niż {max} MB!',
  WORDS_COUNT: 'Niepoprawna liczba słów. "Required": {}',
  FILE_FORMAT_TYPES: 'Dostępne formaty:.pdf,png,. jpg',
  MAJORITY: 'Tylko dla dorosłych',
  DIGITS_AND_LATIN_CAPS_LETTERS: 'Tylko cyfry i duże litery łacińskie',
  INVALID_DATE: 'Nieprawidłowa data'
}

export default {
  default: 'Coś poszło nie tak',
  'user has no roles': 'użytkownik nie ma ról',
  ...serverErrors,
  validate: validationErrors
}
