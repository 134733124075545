import Vue from 'vue'
import VueRouter from 'vue-router'
import authGuard from '@/router/guards/auth-guard'
import eventStartedGuard from '@/router/guards/eventStarted-guard'
import pageEnabledGuard from '@/router/guards/pageEnabled-guard'
import {
  ROLE_USER,
  ROLE_GUEST,
  ROLE_COMMENTATOR,
  ROLE_MODERATOR,
  ROLE_ADMIN
} from '@/constants/roles'
import businessEventService from '@/services/businessEvent'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      roles: [
        ROLE_USER
      ]
    },
    component: {
      render: () => '<div />',
      async created () {
        const liveDay = await businessEventService.getActiveDay()
        const homePageDay = liveDay?.index ? liveDay.index : 1
        this.$router.push(`/page/day/${homePageDay}`)
      }
    }
  },
  {
    path: '/page/day/:day',
    name: 'day',
    meta: {
      roles: [
        ROLE_USER
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (page.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "page" */ '@/modules/PageDay')
  },
  {
    path: '/nominations',
    name: 'nominations',
    meta: {
      roles: [
        ROLE_USER
      ],
      disableScroll: true
    },
    // route level code-splitting
    // this generates a separate chunk (nominations.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName : "nominations" */ '@/modules/PageNominations')
  },
  {
    path: '/schedule',
    name: 'schedule',
    meta: {
      roles: [
        ROLE_USER
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (page.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName : "schedule" */ '@/modules/PageSchedule')
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      roles: [ROLE_GUEST]
    },
    // route level code-splitting
    // this generates a separate chunk (auth.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ '@/modules/PageAuth')
  },
  {
    path: '/my-profile',
    name: 'my-profile',
    meta: {
      roles: [
        ROLE_USER
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (my-profile.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "my-profile" */ '@/modules/PageMyProfile')
  },
  {
    path: '/profile-nominee/:id',
    name: 'profile-nominee',
    meta: {
      roles: [
        ROLE_USER
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (profile-nominee.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "profile-nominee" */ '@/modules/PageProfileNominee')
  },
  {
    path: '/profile-speaker/:id',
    name: 'profile-speaker',
    meta: {
      roles: [
        ROLE_USER
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (profile-speaker.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "profile-speaker" */ '@/modules/PageSpeaker')
  },
  {
    path: '*',
    name: '404',
    meta: {
      roles: [
        ROLE_USER,
        ROLE_COMMENTATOR,
        ROLE_MODERATOR,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "404" */ '@/modules/Page404')
  }
]

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  } else if (to.hash && !to.meta.disableScroll) {
    return {
      selector: to.hash
    }
  } else {
    return { x: 0, y: 0 }
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes
})

/* TODO: Надо написать декоратор, который будет позволять милдвайрам делать много виртуальных редиректов и
 * только один реальный. Так как vue router ругается на 2 и более редиректов подряд */
router.beforeEach(authGuard)
router.beforeEach(eventStartedGuard)
router.beforeEach(pageEnabledGuard)

export default router
