import { EN } from '@/constants/languages'
import i18n from '@/plugins/i18n'
import moment from 'moment'
import * as langAPI from '@/services/api/translatesAndContent'
import { isEqual } from 'lodash'

const PREFIX = process.env.VUE_APP_ACCESS_PREFIX
const LANGUAGE_KEY = PREFIX ? `${PREFIX}:language` : 'language'

export default class LanguageRepository {
  /**
   * @type {Array<Language>}
   * */
  languages = []
  /**
   * @type {Array<string>}
   * */
  languagesNamesList = []
  /**
   * @type {Language | null}
   * */
  defaultLanguage = null

  /**
   * Устанавливает списки языков и язык по умолчанию
   * @param {Array<Language>} langList
   * @param {Language} defaultLang
   * */
  setLanguages (langList, defaultLang) {
    this.languages = langList
    this.languagesNamesList = langList.map(el => el.code)
    this.defaultLanguage = defaultLang
  }

  /**
   * Возвращает код активного языка
   * @return {string}
   * */
  getLanguageCode () {
    const savedLanguage = localStorage.getItem(LANGUAGE_KEY)
    const userLanguage = navigator.language.split('-')[0]

    if (savedLanguage && this.languagesNamesList.includes(savedLanguage)) {
      return savedLanguage
    }

    if (userLanguage && this.languagesNamesList.includes(userLanguage)) {
      return userLanguage
    }

    if (this.defaultLanguage && this.languagesNamesList.includes(this.defaultLanguage.code)) {
      return this.defaultLanguage.code
    }

    return EN
  }

  /**
   * Возвращает объект активного языка
   * @return {Language | undefined}
   * */
  getLanguage () {
    const langCode = this.getLanguageCode()
    return this.getLanguageByCode(langCode)
  }

  /**
   * Возвращает объект языка по его коду
   * @return {Language | undefined}
   * */
  getLanguageByCode (langCode) {
    return this.languages.find(el => el.code === langCode)
  }

  /**
   * Обновляет активный язык
   * @param {Language} langObj
   * @async
   * */
  async updateLanguage (langObj) {
    if (typeof langObj !== 'object' || !langObj.code) throw new Error('lang must be object')
    if (isEqual(this.language, langObj)) return

    const locale = langObj.code
    if (this.languagesNamesList.includes(locale)) {
      // Очередность обязательно такая

      // Сначала устанавливаем язык в LocalStorage
      localStorage.setItem(LANGUAGE_KEY, locale)

      // В глубинах этого вызова будет взят язык из LocalStorage
      await this.updateMessages(locale)

      // Чтобы не было перехода на fallback (en) сначала обновляем словарь и только потом меняем язык
      i18n.locale = locale
      moment.locale(locale)
    }
  }

  /**
   * Обновляет переводы
   * @param {string} locale
   * */
  async updateMessages (locale) {
    const { data } = await langAPI.getMainTranslationContent()
    i18n.mergeLocaleMessage(locale, data)
  }
}
