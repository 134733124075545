/**
 * Делает FormData из BlobUrl
 * @param {String} blobUrl
 * @return {Promise<FormData>} FormData.file
 * */
export async function formDataFromBlobUrl (blobUrl) {
  const fd = new FormData()
  const file = await fetch(blobUrl).then(r => r.blob())
  fd.append('file', file)
  return fd
}

export async function blobFromBlobUrl (blobUrl) {
  return fetch(blobUrl).then(r => r.blob())
}

export async function blobToBase64 (blob) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => { resolve(reader.result) }
  })
}
