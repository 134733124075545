import EventBus from '@/EventBus'
import businessEventService from '@/services/businessEvent'
import { isEqual } from 'lodash'

export default class LanguageService {
  languagesListFetched = false
  languagesListFetchPromise = null

  constructor (repository) {
    this.repository = repository

    EventBus.subscribe('user:init', user => this.handleUserGet(user))

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(async () => {
      this.language = await this.getLanguage()
      this.repository.updateLanguage(this.language)
    }, 0)
  }

  async getLanguage () {
    if (!this.languagesListFetched) {
      await this.fetchEventLanguages()
    }

    const lang = this.repository.getLanguage()
    if (lang) return lang
  }

  async getLanguageCode () {
    if (!this.languagesListFetched) {
      await this.fetchEventLanguages()
    }

    return this.repository.getLanguageCode()
  }

  updateLanguage (langObj) {
    if (typeof langObj !== 'object' || !langObj.code) throw new Error('lang must be object')
    if (isEqual(this.language, langObj)) return

    this.language = langObj

    this.repository.updateLanguage(langObj)
    EventBus.emit('language:update', langObj)
  }

  async fetchEventLanguages () {
    if (this.languagesListFetchPromise) {
      return this.languagesListFetchPromise
    }

    this.languagesListFetchPromise = businessEventService.getEventLanguages()
    const languages = await this.languagesListFetchPromise
    const defaultLang = await businessEventService.getDefaultLanguage()

    this.repository.setLanguages(languages, defaultLang)
    this.languagesListFetched = true
    this.languagesListFetchPromise = null
  }

  updateLanguageByCode (langCode) {
    const lang = this.repository.getLanguageByCode(langCode)
    this.updateLanguage(lang)
  }

  async handleUserGet (user) {
    if (user.language !== this.language?.code) {
      if (!this.languagesListFetched) {
        await this.fetchEventLanguages()
      }

      let newLang = this.repository.getLanguageByCode(user.language)

      if (!newLang) {
        newLang = this.repository.getLanguage()
      }
      this.updateLanguage(newLang)
    }
  }
}
