const serverErrors = {
  'invalid password or username': '@:message.errors.invalidLogin',
  invalidLogin: 'Password o nome utente non validi',
  'internal server error': 'Errore interno del server',
  'not found error': 'Non trovato'
}

const validationErrors = {
  NOT_UNIQUE: 'metro',
  REQUIRED: 'Campo obbligatorio',
  EMAIL: 'E-mail non valida',
  PHONE: 'Telefono non valido',
  UNIVERSAL_INVALID_FIELD: 'Valore campo non valido',
  CYRILLIC_AND_SPACE: 'Cirillico e spazi solo',
  DIGIT: 'Solo cifre',
  ALPHANUMERIC: 'Solo numeri e lettere',
  RANGE_MIN: 'Il campo deve contenere almeno {min} caratteri',
  RANGE_MAX: 'Il campo non deve contenere più di {max} caratteri',
  RANGE_MIN_MAX: 'Il campo deve contenere da {min} a {max} caratteri',
  RANGE_EXACT: 'Il campo deve contenere caratteri: {count}',
  LETTERS_RANGE_MIN: 'Il campo deve contenere almeno {min} lettere',
  LETTERS_RANGE_MAX: 'Il campo non deve contenere più di {max} lettere',
  LETTERS_RANGE_MIN_MAX: 'Il campo deve contenere da {min} a {max} lettere',
  LETTERS_RANGE_EXACT: 'Il campo deve contenere le lettere: {count}',
  FILE_SIZE_MAX: 'La dimensione del File deve essere inferiore a {max} MB!',
  WORDS_COUNT: 'Conteggio delle parole errato. Atteso: {wordsCount}',
  FILE_FORMAT_TYPES: 'Formati disponibili: .PDF, .png, .jpg',
  MAJORITY: 'Solo per adulti',
  DIGITS_AND_LATIN_CAPS_LETTERS: 'Solo numeri e lettere latine maiuscole',
  INVALID_DATE: 'Data non valida'
}

export default {
  default: 'Qualcosa è andato storto',
  'user has no roles': 'l\'utente non ha ruoli',
  ...serverErrors,
  validate: validationErrors
}
