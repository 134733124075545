export const ROLE_GUEST = 'guest'
export const ROLE_USER = 'user'
export const ROLE_COMMENTATOR = 'commentator'
export const ROLE_MODERATOR = 'moderator'
export const ROLE_ADMIN = 'admin'
export const ROLE_WRITE_COMMON_CHAT = 'write_to_common_room'

export default {
  ROLE_GUEST,
  ROLE_USER,
  ROLE_COMMENTATOR,
  ROLE_MODERATOR,
  ROLE_ADMIN,
  ROLE_WRITE_COMMON_CHAT
}
