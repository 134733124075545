import apiClient from '@/services/api/apiClient'
import { EVENT_PREFIX, MEDIA_PREFIX } from '@/constants/apiPrefixes'

export const getActiveEvent = () => apiClient.get(`${EVENT_PREFIX}/events/active`)

export const getEvents = () => apiClient.get(`${EVENT_PREFIX}/events`)

export const updateEventDay = (day) => apiClient.patch(`${EVENT_PREFIX}/days/${day.id}`, day)

export const getTranslatedContentMap = () => apiClient.get(`${MEDIA_PREFIX}/translations`)

export const updateEvent = (event) => apiClient.patch(`${EVENT_PREFIX}/events/${event.id}`, event)

export const getEventsList = () => apiClient.get(`${EVENT_PREFIX}/events`)

export const createEvent = (title) => apiClient.post(`${EVENT_PREFIX}/events`, {
  title
})

export const createBroadcast = (body) => apiClient.post(`${EVENT_PREFIX}/days/${body.day}/broadcasts`, {
  language_id: body.language,
  stream_link: body.stream_link
})

export const updateBroadcast = (body) => apiClient.patch(`${EVENT_PREFIX}/broadcasts/${body.broadcast_id}`, {
  stream_link: body.stream_link
})
