import errors from './errors'
import main from './main'
import vuetify from './vuetify'
// import intro from './intro'

export default {
  $vuetify: vuetify,
  ...main,
  // intro,
  errors
}
