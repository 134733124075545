<template>
  <div
    v-if="isPlayerPage || isPlaying"
    :id="playerId"
    :class="['translation-player', 'player', { 'player--mini': isMiniMode, 'player--hide': !isReadyToShow }]"
  >
    <v-icon
      v-show="isMiniMode"
      class="icon icon--back"
      @click="goToVideoPage"
    >
      mdi-arrow-left-bold-box-outline
    </v-icon>

    <EmbedPlayer
      v-if="!isPlug && hasStreamLink"
      :stream-link="streamLink"
      :player-id="playerId"
    />

    <EmbedPlug
      v-else
      :plug-type="plugType"
      :plug-link="plugLink"
    />

    <v-icon
      v-show="isMiniMode"
      class="icon icon--close"
      @click="closeMiniPlayer"
    >
      mdi-close-box-outline
    </v-icon>
  </div>
</template>

<script>
import EmbedPlayer from './Player'
import EmbedPlug from './Plug'
import { setPlayerStyles } from './helpers'
import { debounce } from 'lodash'
import EventBus from '@/EventBus'

export default {
  components: {
    EmbedPlayer,
    EmbedPlug
  },

  props: {
    /**
     * @type {('video-js' | 'embed')}
     * */
    day: {
      type: Number,
      default: 0
    },

    playerId: {
      type: String,
      default: ''
    },

    streamLink: {
      type: String,
      default: ''
    },
    isPlug: {
      type: Boolean
    },
    plugLink: {
      type: String,
      default: ''
    },
    plugType: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    isPlaying: false,
    isMiniMode: false,
    isPlayerPage: null,
    isReadyToShow: false,
    hasStreamLink: true
  }),

  watch: {
    $route () {
      this.reInitPlayer()
    }
  },

  created () {
    // Facecast запускает видос, фигачит 3 апдейта с состоянием isPlaying === true,
    // после чего стопается и фигачит 4 апдейт, где isPlaying === false
    // из-за этого ложно сбрасывается уже активный в минимоде плеер
    this.debouncedFacecastMessage = debounce(this.onFacecastMessage, 500, {
      leading: false
    })

    window.addEventListener('resize', this.onResize)

    EventBus.subscribe('videoPlayer:showPage', this.recalculateStyles)
    EventBus.subscribe('videoPlayer:presentationResize', this.recalculateStyles)
    EventBus.subscribe('videoPlayer:iframeOnLoad', this.onFacecastIframeLoad)
    EventBus.subscribe('videoPlayer:sourceFrame', this.debouncedFacecastMessage)
    EventBus.subscribe('videoPlayer:isIntersectingContainer', this.onIntersectContainer)

    EventBus.subscribe('language:update', this.closeMiniPlayer)
    EventBus.subscribe('action:logout', this.closeMiniPlayer)

    this.reInitPlayer()
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
    EventBus.unsubscribe('language:update', this.closeMiniPlayer)
    EventBus.unsubscribe('action:logout', this.closeMiniPlayer)
    EventBus.unsubscribe('videoPlayer:isIntersectingContainer', this.onIntersectContainer)
    EventBus.unsubscribe('videoPlayer:sourceFrame', this.debouncedFacecastMessage)
    EventBus.unsubscribe('videoPlayer:iframeOnLoad', this.onFacecastIframeLoad)
    EventBus.unsubscribe('videoPlayer:presentationResize', this.recalculateStyles)
    EventBus.unsubscribe('videoPlayer:showPage', this.recalculateStyles)
  },

  methods: {
    goToVideoPage () {
      this.$router.push(`/page/day/${this.day}`).catch((failure) => {
        console.info(failure)
      })
    },

    reInitPlayer () {
      const { day = -1 } = this.$route.params
      this.isReadyToShow = false
      this.isPlayerPage = String(day) === String(this.day)
      this.isMiniMode = this.isPlaying && !this.isPlayerPage

      this.recalculateStyles()
    },

    closeMiniPlayer () {
      this.isPlaying = false
      this.isMiniMode = false
      this.isVideoHomePage = false
    },

    onResize () {
      EventBus.emit('videoPlayer:showPage', this.$route.params?.day)
    },

    recalculateStyles () {
      this.isReadyToShow = setPlayerStyles(this.playerId)
    },

    onIntersectContainer ({ day, isIntersecting }) {
      if (String(day) === String(this.day)) {
        this.isMiniMode = this.isPlaying && !isIntersecting
        this.recalculateStyles()
      }
    },

    onFacecastMessage ({ sourceFrame, isPlaying }) {
      // ставит локальный статус воспроизведения, если сигнал пришел на текущий плеер
      if (this.playerId === sourceFrame) {
        this.isPlaying = isPlaying
      }

      // закрытие мини плеера в случае, если включается плеер на другой странице
      if (this.playerId !== sourceFrame && isPlaying) {
        this.closeMiniPlayer()
      }
    },

    onFacecastIframeLoad ({ streamLink, day }) {
      this.hasStreamLink = !!streamLink

      if (String(day) === String(this.day)) {
        this.recalculateStyles()
      }
    }
  }
}
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles";

.translation-player {
  border-radius: 20px;
  overflow: hidden;
}

.player {
  position: absolute;

  .icon {
    position: absolute;
    background-color: $white-color;

    &--back {
      top: 0;
      left: 0;
      z-index: 1;
      padding: 0 5px 0 6px;
      border-radius: 0 0 10px;
    }

    &--close {
      padding: 0 5px 0 6px;
      border-radius: 0 0 0 10px;
      top: 0;
      right: 0;
    }
  }

  &--hide {
    display: none;
  }

  &--mini {
    position: fixed;
    width: 400px !important; // нужно перебить инлайн стили
    height: auto !important; // нужно перебить инлайн стили
    top: unset !important; // нужно перебить инлайн стили
    left: unset !important; // нужно перебить инлайн стили
    bottom: 20px;
    right: 15px;
    z-index: 99;

    &::before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }

    .comment_arrow {
      display: none;
    }

    .embed-player {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

@include below(map_get($grid-breakpoints, md)) {
  .player {
    &--mini {
      width: 140px !important;
      height: 80px !important;

      .embed-player iframe {
        width: 140px;
        height: 80px;
      }
    }
  }
}
</style>
