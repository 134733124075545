const serverErrors = {
  'invalid password or username': '@:message.errors.invalidLogin',
  invalidLogin: 'Parolă sau nume de utilizator nevalid',
  'internal server error': 'Eroare internă a serverului',
  'not found error': 'Nu a fost găsit'
}

const validationErrors = {
  NOT_UNIQUE: 'Câmpul cu aceeași valoare există deja',
  REQUIRED: 'Câmp obligatoriu',
  EMAIL: 'E-mail nevalid',
  PHONE: 'Telefon nevalid',
  UNIVERSAL_INVALID_FIELD: 'Valoare câmp nevalid',
  CYRILLIC_AND_SPACE: 'Chirilică și spații numai',
  DIGIT: 'Numai cifre',
  ALPHANUMERIC: 'Numai numere și litere',
  RANGE_MIN: 'Câmpul trebuie să conțină cel puțin {min} caractere',
  RANGE_MAX: 'Câmpul nu trebuie să conțină mai mult de {max} caractere',
  RANGE_MIN_MAX: 'Câmpul trebuie să conțină de la {min} la {max} caractere',
  RANGE_EXACT: 'Câmpul trebuie să conțină caractere: {count}',
  LETTERS_RANGE_MIN: 'Câmpul trebuie să conțină cel puțin {min} litere',
  LETTERS_RANGE_MAX: 'Câmpul nu trebuie să conțină mai mult de {max} litere',
  LETTERS_RANGE_MIN_MAX: 'Câmpul trebuie să conțină litere de la {min} la {max}',
  LETTERS_RANGE_EXACT: 'Câmpul trebuie să conțină litere: {count}',
  FILE_SIZE_MAX: 'File size should be less than {max} MB!',
  WORDS_COUNT: 'Număr incorect de cuvinte. De așteptat: {wordsCount}',
  FILE_FORMAT_TYPES: 'Formate disponibile:.pdf, .png, .jpg',
  MAJORITY: 'Numai pentru adulți',
  DIGITS_AND_LATIN_CAPS_LETTERS: 'Numai numere și majuscule litere latine',
  INVALID_DATE: 'Data nevalidă'
}

export default {
  default: 'Ceva a mers prost',
  'user has no roles': 'utilizatorul nu are roluri',
  ...serverErrors,
  validate: validationErrors
}
