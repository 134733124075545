<template>
  <img
    :alt="alt"
    :src="getLinkByEvent(src)"
    v-bind="$attrs"
    v-on="$listeners"
    @error="handleError"
  >
</template>

<script>
import { getLinkByEvent } from '@/utils/mediaHelpers'

export default {
  name: 'CImg',
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    }
  },
  methods: {
    getLinkByEvent,
    handleError (e) {
      const match = e.target.currentSrc.lastIndexOf('/img')
      const source = e.target.currentSrc.substr(match)
      e.target.src = require('@/assets' + source)
    }
  }
}
</script>
