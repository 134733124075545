var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "layout-main" },
    [
      _c("Header", {
        attrs: {
          "is-hide-header": _vm.isHideHeader,
          user: _vm.user,
          "is-light-theme": _vm.isHeaderLight
        }
      }),
      _vm.selectedEvent ? _c("v-main", [_c("router-view")], 1) : _vm._e(),
      _c("Footer", { attrs: { "is-light-theme": _vm.isFooterLight } }),
      _c("v-overlay", { attrs: { value: !_vm.selectedEvent } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }