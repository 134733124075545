/** Модуль используется из vue.config,
 *  а он отрабатывает на node и нужна реализация require импорта
 * */

const RU = 'ru'
const EN = 'en'
const BG = 'bg'
const DE = 'de'
const IT = 'it'
const PL = 'pl'
const RO = 'ro'
const HU = 'hu'

const LANGUAGES = [RU, EN, BG, DE, IT, PL, RO, HU]

module.exports = {
  LANGUAGES,
  default: LANGUAGES
}
