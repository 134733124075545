export default {
  header: 'header',
  footer: 'footer',
  'main page': 'main page',
  speakers: 'speakers',
  'are you sure want to logout': 'are you sure want to logout',
  'video broadcast': 'video broadcast',
  banner: 'banners',
  tests: 'tests',
  'page not found': 'page not found',
  submit: 'submit',
  'full name': 'full name',
  upload: 'upload',
  'new photo': 'new photo',
  'last name': 'last name',
  'first name': 'first name',
  'middle name': 'patronymic',
  country: 'country',
  city: 'city',
  interests: 'interests',
  'favourite products': 'favourite products',
  achievements: 'achievements',
  save: 'save',
  saved: 'saved',
  cancel: 'cancel',
  'edit profile': 'edit profile',
  logout: 'logout',
  email: 'email',
  avatar: 'avatar',
  'page settings': 'page settings',
  player: 'player',
  'admin panel': 'admin panel',
  users: 'users',
  user: 'user',
  member: 'member',
  members: 'members',
  employee: 'employee',
  employees: 'employees',
  staff: 'staff',
  create: 'create',
  import: 'import',
  language: 'language',
  'coral chips': 'coral chips',
  chat: 'chat',
  poll: 'poll',
  translate: 'translate',
  quote: 'quote',
  question: 'question',
  questions: 'questions',
  answer: 'answer',
  answers: 'answers',
  day: 'day',
  schedule: 'schedule',
  'new ranks': 'new ranks',
  'new comment': 'no comment | {n} new comment | {n} new comments | {n} new comments',
  'test your knowledge': 'test your knowledge',
  'test passed': 'test passed',
  'take the test': 'take the test',
  'forum schedule': 'forum schedule',
  'the broadcast schedule is indicated by moscow time (gmt +3)': 'the broadcast schedule is indicated by moscow time (GMT +3)',
  'watch the recording': 'watch the recording',
  'forum speakers': 'forum speakers',
  'all speakers': 'all speakers',
  'all nominees': 'all nominees',
  'platinum executive': 'platinum executive',
  executive: 'executive',
  'superior master': 'upline executive',
  'star executive': 'star executive',
  'gold executives': 'gold \nexecutives',
  'diamond directors': 'diamond \ndirectors',
  'double silver executives': 'double silver \nexecutives',
  'silver executive': 'silver executive',
  'emerald director': 'emerald director',
  'gold director': 'gold director',
  'new gold executive': 'new gold executive',
  director: 'director',
  executives: 'executives',
  directors: 'directors',
  'silver executives': 'silver \nexecutives',
  'platinum executives': 'platinum executives',
  'bronze executives': 'bronze executives',
  'wood executives': 'wood executives',
  'emerald directors': 'emerald directors',
  'gold directors': 'gold directors',
  GE: 'GE',
  DSE: '2SE',
  SE: 'SE',
  E: 'E',
  DD: 'DD',
  december: 'december',
  january: 'january',
  february: 'february',
  march: 'march',
  april: 'april',
  may: 'may',
  june: 'june',
  july: 'july',
  august: 'august',
  september: 'september',
  october: 'october',
  november: 'november',
  'useful materials': 'useful materials',
  download: 'download',
  'personal area': 'personal area',
  help: 'help',
  score: 'score',
  'helpful information': 'helpful information',
  'just now': 'just now',
  vote: 'vote',
  'leave a comment': 'leave a comment',
  'forum chat': 'forum chat',
  'profile settings': 'profile settings',
  'change photo': 'change photo',
  surname: 'surname',
  name: 'name',
  'enter your comment text': 'enter your comment text',
  congratulate: 'congratulate',
  congratulated: 'congratulated',
  'send message': 'send message',
  copyright: 'All rights prohibited, tyry pyry \n(С) Coral Club, 2020',
  'broadcasting will be available soon': 'broadcasting will be available soon',
  'chat action not available': 'action not available'
}
