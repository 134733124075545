const serverErrors = {
  'invalid password or username': '@:message.errors.invalidLogin',
  invalidLogin: 'Invalid password or username',
  'internal server error': 'Internal server error',
  'not found error': 'Not found'
}

const validationErrors = {
  NOT_UNIQUE: 'Field with same value already exists',
  REQUIRED: 'Required field',
  EMAIL: 'Invalid E-mail',
  PHONE: 'Invalid phone',
  UNIVERSAL_INVALID_FIELD: 'Invalid field value',
  CYRILLIC_AND_SPACE: 'Cyrillic and spaces only',
  DIGIT: 'Digits only',
  ALPHANUMERIC: 'Only numbers and letters',
  RANGE_MIN: 'The field must contain at least {min} characters',
  RANGE_MAX: 'The field must contain no more than {max} characters',
  RANGE_MIN_MAX: 'The field must contain from {min} to {max} characters',
  RANGE_EXACT: 'The field must contain characters: {count}',
  LETTERS_RANGE_MIN: 'The field must contain at least {min} letters',
  LETTERS_RANGE_MAX: 'The field must contain no more than {max} letters',
  LETTERS_RANGE_MIN_MAX: 'The field must contain from {min} to {max} letters',
  LETTERS_RANGE_EXACT: 'The field must contain letters: {count}',
  FILE_SIZE_MAX: 'File size should be less than {max} MB!',
  WORDS_COUNT: 'Incorrect word count. Expected: {wordsCount} ',
  FILE_FORMAT_TYPES: 'Available formats: .pdf, .png, .jpg',
  MAJORITY: 'For adults only',
  DIGITS_AND_LATIN_CAPS_LETTERS: 'Only numbers and capital Latin letters',
  INVALID_DATE: 'Invalid date'
}

export default {
  default: 'Something went wrong',
  'user has no roles': 'user has no roles',
  ...serverErrors,
  validate: validationErrors
}
