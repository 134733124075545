/* eslint-disable */
import EventBus from '@/EventBus'

if (window.addEventListener) {
  window.addEventListener('message', msg_receive)
} else {
  // IE8
  window.attachEvent('onmessage', msg_receive)
}

export function msg_receive (msg) {
    if (msg?.data?.indexOf?.('Facecast_player') > -1) {
      const data = JSON.parse(msg.data)

      EventBus.emit('videoPlayer:sourceFrame', {
        sourceFrame: data?.source_frame,
        isPlaying: data?.isPlaying
      })

      if (data.type === 'write_storage') {
        try {
          window.localStorage.setItem(data.key, data.value)
        } catch (e) {}
      }

      if (data.type === 'update_storage') {
        update_storage(data.source_frame)
      }
    }
}

export function fcLoadIframe (e, streamLink, par = {}) {
  if (!streamLink) {
    console.error('No frame id')
    return
  }

  par.autorun = true
  const streamUrl = new URL(streamLink)

  Object.entries(par).forEach(([key, val]) => {
    streamUrl.searchParams.set(key, val)
  })

  const searchString = document.location.search.slice(1)
  let url = streamUrl.toString()

  if (searchString) {
    const searchArray = searchString.split('&')
    searchArray.forEach( item => {
      if (item.indexOf('utm_') === 0 || item.indexOf('fc_') === 0 || item.indexOf('fcdebug') === 0) {
        url = url + ((url.indexOf('?') > 0) ? '&' : '?') + ((item.indexOf('fc_') === 0) ? item.replace('fc_', '') : item)
      }
    })
  }

  if (e.src !== url) {
    e.src = url
  }
}

export function update_storage (e) {
  var d = {
    usPlaying: true
  }

  try {
    Object.keys(window.localStorage).forEach(function (k) {
      d[k] = window.localStorage[k]
    })
  } catch (e) {}

  var m = JSON.stringify({
    frame: e,
    exec: 'update_storage',
    data: d
  })

  window.frames[0].postMessage(m, '*');
}
