const serverErrors = {
  'invalid password or username': '@:message.errors.invalidLogin',
  invalidLogin: 'Невалидна парола или потребителско име',
  'internal server error': 'Вътрешна грешка на сървъра',
  'not found error': 'Не е намерен'
}

const validationErrors = {
  NOT_UNIQUE: 'Поле със същата стойност вече съществува',
  REQUIRED: 'Задължително поле',
  EMAIL: 'Невалиден имейл',
  PHONE: 'Невалиден телефон',
  UNIVERSAL_INVALID_FIELD: 'Невалидна стойност на полето',
  CYRILLIC_AND_SPACE: 'Само кирилица и пропуски',
  DIGIT: 'Само цифри',
  ALPHANUMERIC: 'Само цифри и букви',
  RANGE_MIN: 'Полето трябва да съдържа поне {min} символи',
  RANGE_MAX: 'Полето трябва да съдържа максимум {max} символи',
  RANGE_MIN_MAX: 'Полето трябва да съдържа от {min} до {max} символи',
  RANGE_EXACT: 'Полето трябва да съдържа символи: {count}',
  LETTERS_RANGE_MIN: 'Полето трябва да съдържа най-малко {min} букви',
  LETTERS_RANGE_MAX: 'Полето трябва да съдържа максимум {max} букви',
  LETTERS_RANGE_MIN_MAX: 'Полето трябва да съдържа от {min} до {max} букви',
  LETTERS_RANGE_EXACT: 'Полето трябва да съдържа букви: {count}',
  FILE_SIZE_MAX: 'Размерът на файла трябва да бъде по-малък {max} MB!',
  WORDS_COUNT: 'Грешен брой думи. Очаква се: {wordsCount} ',
  FILE_FORMAT_TYPES: 'Налични формати:. pdf,. png,. jpg',
  MAJORITY: 'Само за възрастни',
  DIGITS_AND_LATIN_CAPS_LETTERS: 'Само цифри и главни латински букви',
  INVALID_DATE: 'Грешна дата'
}

export default {
  default: 'Нещо се обърка.',
  'user has no roles': 'потребителят няма Роли',
  ...serverErrors,
  validate: validationErrors
}
