import { cloneDeep } from 'lodash'

export default class User {
  userFetchPromise = null

  constructor ({ eventEmitter, store, persistedStorage }) {
    this.eventEmitter = eventEmitter
    this.store = store
    this.persistedStorage = persistedStorage

    this.eventEmitter.subscribe('language:update', async language => {
      const user = await this.getUser()
      if (typeof language !== 'object' || !language.code) throw new Error('lang must be object')

      if (user && user.languageId !== language.id) {
        store.dispatch('updateUserLanguage', language)
      }
    })
  }

  /**
   * Возвращает текущего авторизованного пользователя
   * @param {boolean} force
   * @return {Promise<UserInstance>}
   * */
  async getUser (force = false) {
    if (this.store.state.user && !force) {
      return cloneDeep(this.store.state.user)
    }

    if (!this.userFetchPromise) {
      this.userFetchPromise = new Promise(resolve => {
        this.store.dispatch('getUser')
          .then(() => {
            resolve(cloneDeep(this.store.state.user))
            this.userFetchPromise = null
          })
      })
    }

    return this.userFetchPromise
  }

  logout () {
    this.persistedStorage.removeUser()
    this.store.dispatch('logout')
  }

  async updateUser (payload) {
    await this.store.dispatch('updateUser', payload)
    return cloneDeep(this.store.state.user)
  }

  async initUser (payload) {
    await this.store.dispatch('initUser', payload)
    return this.store.state.user
  }
}
