var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "embed-player" }, [
    _vm.plugType === "image"
      ? _c("img", {
          staticClass: "embed-player__plug-image",
          attrs: { src: _vm.plugLink, alt: "plug" }
        })
      : _c("iframe", {
          attrs: {
            src: _vm.plugLink,
            allow:
              "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
            allowfullscreen: ""
          }
        })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }