const serverErrors = {
  'invalid password or username': '@:message.errors.invalidLogin',
  invalidLogin: 'Неверно указан логин или пароль',
  'internal server error': 'Внутренняя ошибка сервера',
  'not found error': 'Не найдено'
}

const validationErrors = {
  NOT_UNIQUE: 'Поле с таким значением уже зарегистрировано',
  REQUIRED: 'Обязательное поле',
  EMAIL: 'Введите правильный E-mail',
  PHONE: 'Неправильно набранный номер',
  UNIVERSAL_INVALID_FIELD: 'Неверно заполненное поле',
  CYRILLIC_AND_SPACE: 'Только кириллица и пробелы',
  DIGIT: 'Только цифры',
  ALPHANUMERIC: 'Только цифры и буквы',
  RANGE_MIN: 'Поле должно содержать не менее {min} символов',
  RANGE_MAX: 'Поле должно содержать не более {max} символов',
  RANGE_MIN_MAX: 'Поле должно содержать от {min} до {max} символов',
  RANGE_EXACT: 'Поле должно содержать символов: {count}',
  LETTERS_RANGE_MIN: 'Поле должно содержать не менее {min} букв',
  LETTERS_RANGE_MAX: 'Поле должно содержать не более {max} букв',
  LETTERS_RANGE_MIN_MAX: 'Поле должно содержать от {min} до {max} букв',
  LETTERS_RANGE_EXACT: 'Поле должно содержать букв: {count}',
  FILE_SIZE_MAX: 'Файл не должен превышать {max}MB',
  WORDS_COUNT: 'Неверное количеств слов. Ожидается: {wordsCount}',
  FILE_FORMAT_TYPES: 'Доступные форматы: .pdf, .png, .jpg',
  MAJORITY: 'Только для совершеннолетних',
  DIGITS_AND_LATIN_CAPS_LETTERS: 'Только цифры и заглавные латинские буквы',
  INVALID_DATE: 'Неправильная дата'
}

export default {
  default: 'Что-то пошло не так',
  'user has no roles': 'пользователь не имеет ни одной роли',
  'comments loading error': 'ошибка загрузки комментариев',
  ...serverErrors,
  validate: validationErrors
}
