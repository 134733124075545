import { ROLE_GUEST } from '@/constants/roles'

const PREFIX = process.env.VUE_APP_ACCESS_PREFIX
const ACCESS_KEY = PREFIX ? `${PREFIX}:access` : 'access'

export const setAccess = (accessArg) => {
  let access = accessArg
  if (typeof access !== 'object') {
    try {
      access = JSON.parse(accessArg)
    } catch {
      throw new TypeError('argument must be object or JSON string')
    }
  }
  if (!access.roles.length) throw new Error('user has no roles')

  localStorage.setItem(ACCESS_KEY, JSON.stringify({
    accessToken: access.accessToken,
    centrifugoToken: access.centrifugoToken,
    roles: access.roles,
    permissions: access.permissions
  }))
}

export const getAccess = () => {
  const access = JSON.parse(localStorage.getItem(ACCESS_KEY))
  if (access) return access

  return {
    accessToken: null,
    centrifugoToken: null,
    permissions: null,
    roles: [ROLE_GUEST]
  }
}

export const logout = () => {
  localStorage.removeItem(ACCESS_KEY)
}

export const getRoles = () => getAccess().roles

export default {
  setAccess,
  getAccess,
  logout,
  getRoles
}
