<template>
  <div>
    <notifications />

    <component
      :is="layoutName"
      v-if="$route.name"
    />

    <Player
      v-for="(item, index) in daysList"
      :key="item.id + index + item.day + item.languageId"
      :player-id="item.id"
      :stream-link="item.streamLink"
      :is-plug="item.isPlug"
      :plug-link="item.plugLink"
      :plug-type="item.plugType"
      :day="item.day"
    />

    <v-tour
      name="tour"
      :callbacks="tourCallbacks"
      :steps="steps"
      :options="tourOptions"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import * as authService from '@/services/auth'
import businessEventService from '@/services/businessEvent'
import userService from '@/services/user'
import LayoutMain from '@/modules/LayoutMain'
import LayoutAuth from '@/modules/LayoutAuth'
import EventBus from '@/EventBus'
import Player from '@/components/Player'
import languageService from '@/services/language'

export default {
  components: {
    LayoutMain,
    LayoutAuth,
    Player
  },

  data: () => ({
    isMiniMode: false,
    selectedEvent: null,
    isVideoHomePage: false,
    daysList: [],
    tourCallbacks: {
      onSkip: () => {
        Vue.$cookies.set('tour_skipped', true) // , 60 * 60 * 24 * 7
      },
      onFinish: () => {
        Vue.$cookies.set('tour_skipped', true) // , 60 * 60 * 24 * 7
      }
    }
  }),

  computed: {
    layoutName () {
      return ['auth'].includes(this.$route.name) ? 'layout-auth' : 'layout-main'
    },

    tourOptions () {
      return {
        highlight: false,
        labels: {
          buttonSkip: this.$t('intro buttonLabels skip'),
          buttonPrevious: this.$t('intro buttonLabels previous'),
          buttonNext: this.$t('intro buttonLabels next'),
          buttonStop: this.$t('intro buttonLabels stop')
        }
      }
    },

    steps () {
      return [
        // {
        //   header: {
        //     title: 'Добро пожаловать'
        //   },
        //   content: 'Вступительный текст про сервис'
        // },
        {
          target: '.header__inner', // We're using document.querySelector() under the hood
          header: {
            title: this.$t('intro header general title')
          },
          offset: -10,
          content: this.$t('intro header general description')
        },
        {
          target: '.header__days',
          header: {
            title: this.$t('intro header days title')
          },
          offset: -10,
          content: this.$t('intro header days description')
        },
        {
          target: '.header-lang-selector',
          header: {
            title: this.$t('intro header lang title')
          },
          offset: -10,
          content: this.$t('intro header lang description')
        },
        {
          target: '.topmenu__help-link',
          header: {
            title: this.$t('intro header help title')
          },
          offset: -10,
          content: this.$t('intro header help description')
        },
        {
          target: '.broadcast-video',
          header: {
            title: this.$t('intro player general title')
          },
          offset: -10,
          content: this.$t('intro player general description'),
          params: {
            placement: 'right-start'
          }
        },
        {
          target: '.embed-player__emotions, .broadcast-video', // temporary
          header: {
            title: this.$t('intro player emoji title')
          },
          offset: -10,
          content: this.$t('intro player emoji description'),
          params: {
            placement: 'left-end'
          }
        },
        {
          target: '.expert-chat__event-list, .chat-open-link',
          header: {
            title: this.$t('intro player commentator title')
          },
          offset: -10,
          content: this.$t('intro player commentator description'),
          params: {
            placement: 'left-start'
          }
        },
        {
          target: '.broadcast-video',
          header: {
            title: this.$t('intro player presentation title')
          },
          offset: -10,
          content: this.$t('intro player presentation description'),
          params: {
            placement: 'right-start'
          }
        },
        {
          target: '.knowledge-slider-block-container',
          header: {
            title: this.$t('intro tests general title')
          },
          offset: -90,
          content: this.$t('intro tests general description'),
          params: {
            placement: 'top'
          }
        },
        {
          target: '.mp-schedule-block',
          header: {
            title: this.$t('intro schedule general title')
          },
          offset: -10,
          content: this.$t('intro schedule general description'),
          params: {
            placement: 'left-start'
          }
        },
        {
          target: '.mp-speaker-all-link',
          header: {
            title: this.$t('intro forumSpeakers general title')
          },
          offset: -10,
          content: this.$t('intro forumSpeakers general description'),
          params: {
            placement: 'left-start'
          }
        },
        {
          target: '.useful-material-slider-block .slick-list',
          header: {
            title: this.$t('intro docs general title')
          },
          offset: -10,
          content: this.$t('intro docs general description')
        },
        {
          target: '.mp-rank-items-block',
          header: {
            title: this.$t('intro rangs general title')
          },
          offset: -240,
          content: this.$t('intro rangs general description'),
          params: {
            placement: 'top'
          }
        }
        // {
        //   target: '.footer-menu__item-link--help',
        //   header: {
        //     title: this.$t('intro faq general title')
        //   },
        //   content: this.$t('intro faq general description')
        // }
      ]
    }
  },

  watch: {
    $route (to, from) {
      if (!from.name) return
      this.closeTour()
    }
  },

  async created () {
    this.actionLogout = this.actionLogout.bind(this)
    this.action404 = this.action404.bind(this)
    this.initDaysList = this.initDaysList.bind(this)
    this.selectedEvent = await businessEventService.getActiveEvent()
    this.setActiveEventSelector()
    this.setLanguageSelector()

    EventBus.subscribe('action:logout', this.actionLogout)
    EventBus.subscribe('action:404', this.action404)
    EventBus.subscribe('language:update', this.initDaysList)
    EventBus.subscribe('language:update', this.setLanguageSelector)
    EventBus.subscribe('businessEvent:update', this.initDaysList)
    EventBus.subscribe('action:openLogoutDialog', this.closeTour)

    this.initDaysList()
  },

  beforeDestroy () {
    EventBus.unsubscribe('action:logout', this.actionLogout)
    EventBus.unsubscribe('action:404', this.action404)
    EventBus.unsubscribe('language:update', this.initDaysList)
    EventBus.unsubscribe('businessEvent:update', this.initDaysList)
    EventBus.unsubscribe('action:openLogoutDialog', this.closeTour)
  },

  mounted () {
    // <!-- Global site tag (gtag.js) - Google Analytics -->
    const analyticScript = document.createElement('script')
    analyticScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-6WXW35TQHE'

    analyticScript.onload = function () {
      window.dataLayer = window.dataLayer || []
      window.gtag = function (...args) { window.dataLayer.push(...args) }

      window.gtag('js', new Date())

      window.gtag('config', 'G-6WXW35TQHE')
    }

    document.body.appendChild(analyticScript)
  },

  methods: {
    setActiveEventSelector () {
      document.documentElement.classList.add(`event-${this.selectedEvent.id}`)
    },

    setLanguageSelector () {
      const lang = localStorage.getItem('public:language')
      document.getElementsByTagName('html')[0].setAttribute('current-lang', lang)
    },

    closeTour () {
      this.$tours.tour.stop()
    },

    actionLogout () {
      authService.logout()
      userService.logout()

      this.$router.push('/auth')
    },

    action404 () {
      if (this.$route.path !== '/404') { this.$router.push('/404') }
    },

    async initDaysList () {
      const eventDays = await businessEventService.getEventDays()
      const lang = await languageService.getLanguage()

      if (!lang) return

      const daysList = eventDays.map((item) => {
        const broadcast = item.broadcast.find(el => el.languageId === lang.id)

        let id = null

        if (broadcast?.streamLink && broadcast.streamLink.includes('//facecast.net/v/')) {
          const url = new URL(broadcast?.streamLink)
          const streamLinkPath = url.pathname
          id = streamLinkPath.split('/').reverse()[0]
        }

        return {
          day: item.index,
          isBroadcastStart: broadcast && item.isBroadcastStart && (Boolean(id) || (broadcast ? broadcast.isPlug : false)),
          ...broadcast,
          id
        }
      })

      this.daysList = daysList.filter(item => item.isBroadcastStart)
    }
  }
}
</script>

<style lang="scss">
.notification-content {
  font-family: "BebasNeuePro", sans-serif;
  font-size: 21px;
}
</style>
