const serverErrors = {
  'invalid password or username': '@:message.errors.invalidLogin',
  invalidLogin: 'Vigane parool või kasutajanimi',
  'internal server error': 'Sisemine serveri viga',
  'not found error': 'Ei leitud'
}

const validationErrors = {
  NOT_UNIQUE: 'Sama väärtusega väli on juba olemas',
  REQUIRED: 'Nõutav väli',
  EMAIL: 'Vigane E-kiri',
  PHONE: 'Vigane telefon',
  UNIVERSAL_INVALID_FIELD: 'Vigane välja väärtus',
  CYRILLIC_AND_SPACE: 'Kirillitsa ja tühikud ainult',
  DIGIT: 'Ainult numbrid',
  ALPHANUMERIC: 'Ainult numbrid ja tähed',
  RANGE_MIN: 'Väli peab sisaldama vähemalt {min} märke',
  RANGE_MAX: 'Väli ei tohi sisaldada rohkem kui {max} märke',
  RANGE_MIN_MAX: 'Väli peab sisaldama {min} kuni {max} märke',
  RANGE_EXACT: 'Väli peab sisaldama märke: {count}',
  LETTERS_RANGE_MIN: 'Väli peab sisaldama vähemalt {min} tähti',
  LETTERS_RANGE_MAX: 'Väli ei tohi sisaldada rohkem kui {max} tähti',
  LETTERS_RANGE_MIN_MAX: 'Väli peab sisaldama {min} kuni {max} tähti',
  LETTERS_RANGE_EXACT: 'Väli peab sisaldama tähti: {krahv}',
  FILE_SIZE_MAX: 'Faili suurus peaks olema väiksem kui {max} MB!',
  WORDS_COUNT: 'Vale sõnade arv. Oodatud: {wordsCount}',
  FILE_FORMAT_TYPES: 'Saadaval formaadid:.pdf, .png, .jpg',
  MAJORITY: 'Ainult täiskasvanutele',
  DIGITS_AND_LATIN_CAPS_LETTERS: 'Ainult numbrid ja Ladina suurtähed',
  INVALID_DATE: 'Vigane kuupäev'
}

export default {
  default: 'Midagi läks valesti',
  'user has no roles': 'kasutajal pole rolle',
  ...serverErrors,
  validate: validationErrors
}
