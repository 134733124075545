<template>
  <v-app class="layout-auth">
    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <!-- If using vue-router -->
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import vuetify from '@/plugins/vuetify'

export default {
  name: 'AuthLayout',
  vuetify
}
</script>
