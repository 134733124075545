import { ROLE_ADMIN } from '@/constants/roles'
import * as authService from '@/services/auth'
import businessEventService from '@/services/businessEvent'

export default async (to, from, next) => {
  const currentRoles = authService.getRoles()
  const currentEvent = await businessEventService.getActiveEvent()

  /* Если мероприятие не стартовало */
  if (!currentEvent?.isStarted && !['my-profile', 'auth', '404'].includes(to.name)) {
    if (!currentRoles.includes(ROLE_ADMIN)) {
      return next('/my-profile')
    }
  }

  return next()
}
