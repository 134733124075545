import * as persistedStorage from './persistedStorage'
import store, { defaultState as defaultStateLocal } from './store'
import EventBus from '@/EventBus'
import User from './User'

export const defaultState = defaultStateLocal

export default new User({
  store,
  eventEmitter: EventBus,
  persistedStorage
})
