import { ROLE_GUEST } from '@/constants/roles'
import * as authService from '@/services/auth'
import { notifyError } from '@/utils/notify'

export default async (to, from, next) => {
  const currentRoles = authService.getRoles()

  if (!currentRoles.length) {
    const message = 'user has no roles'
    authService.logout()
    notifyError(message)
    return next('/auth')
  }

  /* Если маршрут вложенный и у него нет своих ограничений по ролям,
  *  то взять ограничения у родительского маршрута */
  let roles = to.meta?.roles
  if (!roles) {
    for (let i = to.matched.length - 1; i >= 0; i--) {
      const route = to.matched[i]
      if (!route.meta?.roles) continue
      roles = route.meta.roles
      break
    }
  }

  /* Если пользователь не авторизован */
  if (currentRoles[0] === ROLE_GUEST && to.name !== 'auth') {
    return next('/auth')
  }

  /* Если уже авторизован но переходит на auth */
  if (currentRoles[0] !== ROLE_GUEST && to.name === 'auth') {
    return next('/')
  }

  if (to.path === '/404' || roles.some(role => currentRoles.includes(role))) {
    return next()
  }

  return next('/404')
}
