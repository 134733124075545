const serverErrors = {
  'invalid password or username': '@:message.errors.invalidLogin',
  invalidLogin: 'Ungültiges Passwort oder Benutzername',
  'internal server error': 'Interner Serverfehler',
  'not found error': 'Nicht gefunden'
}

const validationErrors = {
  NOT_UNIQUE: 'Feld mit demselben Wert ist bereits vorhanden',
  REQUIRED: 'Pflichtfeld',
  EMAIL: 'Ungültige E-mail',
  PHONE: 'Ungültiges Telefon',
  UNIVERSAL_INVALID_FIELD: 'Ungültiger Feldwert',
  CYRILLIC_AND_SPACE: 'Nur Kyrillisch und Leerzeichen',
  DIGIT: 'Nur Ziffern',
  ALPHANUMERIC: 'Nur zahlen und Buchstaben',
  RANGE_MIN: 'Das Feld muss mindestens {min} Zeichen',
  RANGE_MAX: 'Das Feld darf nicht mehr als {max} Zeichen enthalten',
  RANGE_MIN_MAX: 'Das Feld muss Zeichen von {min} bis {max} enthalten',
  RANGE_EXACT: 'Das Feld muss Zeichen enthalten: {count}',
  LETTERS_RANGE_MIN: 'Das Feld muss mindestens {min} Buchstaben',
  LETTERS_RANGE_MAX: 'Das Feld darf nicht mehr als {max} Buchstaben enthalten',
  LETTERS_RANGE_MIN_MAX: 'Das Feld muss Buchstaben von {min} bis {max} enthalten',
  LETTERS_RANGE_EXACT: 'Das Feld muss Buchstaben enthalten: {count}',
  FILE_SIZE_MAX: 'Die Dateigröße sollte kleiner als {max} MB!',
  WORDS_COUNT: 'Falsche Wortanzahl. Erwartet: {wordsCount}',
  FILE_FORMAT_TYPES: 'Verfügbare Formate: .PDF, .png -, .jpg',
  MAJORITY: 'For adults only',
  DIGITS_AND_LATIN_CAPS_LETTERS: 'Nur zahlen und lateinische Großbuchstaben',
  INVALID_DATE: 'Ungültiges Datum'
}

export default {
  default: 'Etwas ging schief',
  'user has no roles': 'Benutzer hat keine Rollen',
  ...serverErrors,
  validate: validationErrors
}
