export const EVENTS_STATIC_DATA = [
  {
    id: 'caec76fb-2f2f-4625-85f3-3845666c22c1',
    dateOfThe: '18-20 июля 2021 года',
    path: 'theme',
    name: 'theme',
    prefix: '',
    banner1: {
      pretitle: 'banner_1_pretitle',
      title: 'banner_1_title',
      subtitle: 'banner_1_subtitle',
      image: 'banner1-image.png',
      backgroundImage: 'banner1-bg.png',
      button: {
        label: 'banner_1_button_text',
        link: 'banner_1_link'
      }
    },
    banner2: {
      pretitle: 'banner_2_pretitle',
      title: 'banner_2_title',
      subtitle: 'banner_2_subtitle',
      image: 'banner2-image.png',
      backgroundImage: 'banner2-bg.jpg',
      button: {
        label: 'banner_2_button_text',
        link: 'banner_2_link'
      }
    }
  },
  {
    id: '68b790f4-f645-45f3-84cd-27761a50793b',
    dateOfThe: '13-14 февраля 2021 года',
    path: 'theme',
    name: 'theme',
    prefix: ''
  },
  {
    id: 'dc8ba82f-ea3d-4a5b-8c89-ccf9567bfdbb',
    path: 'theme-forum-22',
    name: 'theme-forum-22',
    prefix: 'theme_forum_22_',
    banner1: {
      pretitle: 'banner_1_pretitle',
      title: 'banner_1_title',
      subtitle: 'banner_1_subtitle',
      backgroundImage: 'banner1-bg.png',
      backgroundImageMobile: 'mobile-banner-1-bg.png',
      imageMobile: 'mobile-banner-1-image.png',
      image: 'banner1-image.png',
      button: {
        label: 'banner_1_button_text',
        link: 'banner_1_link'
      }
    },
    banner2: {
      pretitle: 'banner_2_pretitle',
      title: 'banner_2_title',
      subtitle: 'banner_2_subtitle',
      image: 'banner2-image.png',
      imageMobile: 'mobile-banner-2-image.png',
      backgroundImage: 'banner2-bg.jpg',
      backgroundImageMobile: 'mobile-banner-2-bg.png',
      button: {
        label: 'banner_2_button_text',
        link: 'banner_2_link'
      }
    }
  }
]

export const THEME_FORUM_22 = 'theme-forum-22'
