export default {
  badge: 'знак',
  close: 'Близо',
  dataIterator: {
    noResultsText: 'Няма намерени съответстващи записи',
    loadingText: 'Записът се зарежда ...'
  },
  dataTable: {
    itemsPerPageText: 'Редове на страница:',
    ariaLabel: {
      sortDescending: 'Низходящ ред.',
      sortAscending: 'Сортирано във възходящ ред.',
      sortNone: 'Не е поръчано.',
      activateNone: 'Активирайте, за да премахнете сортирането.',
      activateDescending: 'Активирайте за низходящ ред.',
      activateAscending: 'Активирайте, за да сортирате във възходящ ред.'
    },
    sortBy: 'Сортирай по'
  },
  dataFooter: {
    itemsPerPageText: 'Записи на страница:',
    itemsPerPageAll: 'Всичко',
    nextPage: 'Следваща страница',
    prevPage: 'Предишна страница',
    firstPage: 'Първа страница',
    lastPage: 'последна страница',
    pageText: '{0} - {1} от {2}'
  },
  datePicker: {
    itemsSelected: '{0} избрани',
    nextMonthAriaLabel: 'Следващият месец',
    nextYearAriaLabel: 'Следващата година',
    prevMonthAriaLabel: 'Миналия месец',
    prevYearAriaLabel: 'Миналата година'
  },
  noDataText: 'Липсва информация',
  carousel: {
    prev: 'Предишен слайд',
    next: 'Следващ слайд',
    ariaLabel: {
      delimiter: 'Слайд {0} от {1}'
    }
  },
  calendar: {
    moreEvents: 'Още {0}'
  },
  fileInput: {
    counter: 'Файлове: {0}',
    counterSize: 'Файлове: {0} (общо {1})'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Навигация по страници',
      next: 'Следваща страница',
      previous: 'Предишна страница',
      page: 'Отидете на страница {0}',
      currentPage: 'Текуща страница, страница {0}'
    }
  }
}
