import { EVENTS_STATIC_DATA } from '@/constants/events'

export function getCurrentEventStaticData (eventId) {
  return EVENTS_STATIC_DATA.find(item => item.id === eventId)
}

export function getThemePath () {
  const theme = getCurrentEventStaticData(window.eventDataID)
  return (theme && theme.path) || 'theme'
}
