export default {
  'page not found': 'Pagina non trovata',
  submit: 'Presentare',
  'full name': 'nome completo',
  upload: 'caricare',
  'new photo': 'nuova foto',
  'last name': 'cognome',
  'first name': 'nome',
  'middle name': 'patronimico',
  country: 'paese',
  city: 'citta',
  interests: 'interesse',
  'favourite products': 'prodotti preferiti',
  achievements: 'raggiungimento',
  save: 'salvare',
  saved: 'salvare',
  cancel: 'annullare',
  'edit profile': 'Modifica profilo',
  logout: 'esci',
  email: 'email',
  avatar: 'avatar',
  player: 'giocatore',
  'admin panel': 'Pannello di amministrazione',
  users: 'internauti',
  user: 'utente',
  create: 'creare',
  import: 'importazione',
  language: 'lingua',
  'coral chips': 'chip di corallo',
  'chat action not available': 'azione non disponibile'
}
