import { cloneDeep, isEqual } from 'lodash'
import EventBus from '@/EventBus'

export default class BusinessEvent {
  eventPromise = null
  user = null

  constructor ({ store, eventEmitter, userService }) {
    this.store = store
    this.eventEmitter = eventEmitter
    this.userSevice = userService
    this.rememberCurrentUser()

    const getActiveEvent = () => this.store.dispatch('getActiveEvent')
    EventBus.subscribe('ws:event_service', getActiveEvent)

    EventBus.subscribe('user:update', (user) => {
      if (isEqual(this.user, user)) return

      this.rememberCurrentUser()
      EventBus.unsubscribe('ws:event_service', getActiveEvent)
      if (user) EventBus.subscribe('ws:event_service', getActiveEvent)
    })

    store.subscribe((mutation, state) => {
      if (mutation.type === 'setEvent') {
        this.eventEmitter.emit('businessEvent:update', cloneDeep(state.event))
      }
    })
  }

  async rememberCurrentUser () {
    this.user = await this.userSevice.getUser()
  }

  async getActiveEvent () {
    if (this.store.state.event) {
      return cloneDeep(this.store.state.event)
    }

    if (!this.eventPromise) {
      this.eventPromise = new Promise(resolve => {
        return this.store.dispatch('getActiveEvent')
          .then(() => {
            resolve(cloneDeep(this.store.state.event))
            this.eventPromise = null
          })
      })
    }

    return this.eventPromise
  }

  async getSettings (name) {
    if (!this.store.state.settings) {
      await this.getActiveEvent()
    }
    return cloneDeep(this.store.state.settings?.find?.(r => r.name === name)?.elements ?? [])
  }

  async getStaticEvent () {
    if (!this.store.state.currentStaticEvent) {
      await this.getActiveEvent()
    }
    return cloneDeep(this.store.state.currentStaticEvent)
  }

  async getEventDays () {
    if (!this.store.state.eventDays) {
      await this.getActiveEvent()
    }
    return cloneDeep(this.store.state.eventDays)
  }

  async getActiveDay () {
    const eventDays = await this.getEventDays()
    return eventDays.find(el => el.isDayStart)
  }

  async getEventLanguages () {
    if (!this.store.state.languages) {
      await this.getActiveEvent()
    }

    return cloneDeep(this.store.state.languages)
  }

  async getDefaultLanguage () {
    if (!this.store.state.defaultLanguage) {
      await this.getActiveEvent()
    }

    return cloneDeep(this.store.state.defaultLanguage)
  }

  async updateEvent (payload) {
    await this.store.dispatch('updateEvent', payload)
    return cloneDeep(this.store.state.event)
  }

  async updateEventDay (payload) {
    await this.store.dispatch('updateEventDay', payload)
    return this.store.state.eventDays.find(el => el.id === payload.id)
  }

  async getEventsList () {
    await this.store.dispatch('getEventsList')
    return cloneDeep(this.store.state.eventsList)
  }

  async getTranslatedContentMap () {
    if (!this.store.state.translatedContent) {
      await this.getActiveEvent()
    }

    return cloneDeep(this.store.state.translatedContent)
  }
}
