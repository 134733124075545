import store from './store'
import BusinessEvent from './BusinessEvent'
import EventBus from '@/EventBus'
import userService from '@/services/user'

export default new BusinessEvent({
  store,
  eventEmitter: EventBus,
  userService
})
