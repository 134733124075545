<template>
  <v-select
    class="header-lang-selector text-uppercase"
    :menu-props="{ contentClass: 'header-lang-selector__content'}"
    :items="languages"
    :value="currentLanguage"
    background-color="transparent"
    :item-text="item => item.code.toUpperCase()"
    item-value="code"
    color="white"
    hide-details
    dense
    solo
    flat
    dark
    append-icon="mdi-chevron-down"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      default: null
    },

    languages: {
      type: Array,
      default: () => ([])
    }
  },

  computed: {
    currentLanguage: {
      get () {
        return this.value
      },
      set (item) {
        this.$emit('input', item)
      }
    }
  }
}
</script>

<style lang="scss">
.header-lang-selector {
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  background: transparent;
  font-size: 21px !important;
  font-family: BebasNeuePro, sans-serif;
  line-height: 1.25;
  color: var(--header-footer-header-font-color) !important;
  text-transform: uppercase;
  letter-spacing: 0.03em;

  .v-select__selection {
    color: var(--header-footer-header-font-color) !important;
  }

  .v-select__selections {
    input {
      display: none;
    }
  }

  .v-input__slot {
    padding: 0 !important;
  }

  .v-icon {
    color: var(--header-footer-header-font-color) !important;
  }
}

.header-lang-selector__content {
  background: transparent;

  .v-list-item__title {
    background: transparent;
    font-size: 21px !important;
    font-family: BebasNeuePro, sans-serif !important;
    line-height: 1.25;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }

  .v-list-item {
    &:not(:last-child) {
      border-bottom: 1px solid lightgray;
    }
  }
}
</style>
