export default {
  'page not found': 'Seite nicht gefunden',
  submit: 'Abgeben',
  'full name': 'vollständiger name',
  upload: 'laden',
  'new photo': 'neues Foto',
  'last name': 'Nachname',
  'first name': 'Vorname',
  'middle name': 'Vatersname',
  country: 'country',
  city: 'Land',
  interests: 'Verbraucherinteressen',
  'favourite products': 'Lieblingsprodukte',
  achievements: 'Errungenschaften',
  save: 'speichern',
  saved: 'saved',
  cancel: 'Spielstand',
  'edit profile': 'Profil Bearbeiten',
  logout: 'abmelden',
  email: 'Email',
  avatar: 'Avatare',
  player: 'Spieler',
  'admin panel': 'admin-panel',
  users: 'Internetnutzer',
  user: 'Benutzer',
  create: 'erstellen',
  import: 'importieren',
  language: 'sprachlich',
  'coral chips': 'Korallen-chips',
  'broadcasting will be available soon': 'die Sendung wird später verfügbar sein',
  'chat action not available': 'aktion nicht verfügbar'
}
