export const setPlayerStyles = (elementId) => {
  const videoPreview = document.getElementById('video-previev')
  const playerContainer = document.getElementById(elementId)

  if (!videoPreview || !playerContainer) return false
  const box = videoPreview.getBoundingClientRect()

  const styles = {
    top: box.top + pageYOffset,
    left: box.left + pageXOffset,
    height: box.height,
    width: box.width
  }

  for (const key in styles) playerContainer.style[key] = `${styles[key]}px`

  return true
}
