var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPlayerPage || _vm.isPlaying
    ? _c(
        "div",
        {
          class: [
            "translation-player",
            "player",
            {
              "player--mini": _vm.isMiniMode,
              "player--hide": !_vm.isReadyToShow
            }
          ],
          attrs: { id: _vm.playerId }
        },
        [
          _c(
            "v-icon",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isMiniMode,
                  expression: "isMiniMode"
                }
              ],
              staticClass: "icon icon--back",
              on: { click: _vm.goToVideoPage }
            },
            [_vm._v(" mdi-arrow-left-bold-box-outline ")]
          ),
          !_vm.isPlug && _vm.hasStreamLink
            ? _c("EmbedPlayer", {
                attrs: {
                  "stream-link": _vm.streamLink,
                  "player-id": _vm.playerId
                }
              })
            : _c("EmbedPlug", {
                attrs: { "plug-type": _vm.plugType, "plug-link": _vm.plugLink }
              }),
          _c(
            "v-icon",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isMiniMode,
                  expression: "isMiniMode"
                }
              ],
              staticClass: "icon icon--close",
              on: { click: _vm.closeMiniPlayer }
            },
            [_vm._v(" mdi-close-box-outline ")]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }