/**
 * Глобальные оповещения
 * @module Utils/notify
 * */

import Vue from 'vue'
import i18n from '@/plugins/i18n'

/**
 * Сравнить сообщение с картой серверных ошибок,
 * если есть совпадение вывести локализованное сообщение
 * @function
 * @param {String} message
 * @param {Object|Array} i18nArgs
 * @return {String}
 * */
export function parseServerErrors (message, i18nArgs) {
  if (!message) return i18n.t('errors.default')
  if (i18n.te(`errors.${message}`)) {
    return i18n.t(`errors.${message}`, i18nArgs)
  }
  return message
}

/**
 * Глобальное оповещение
 * @function
 * @param {Object} data - Настройки оповещения
 * @param {String} data.type - Тип (info|warn|error|success)
 * @param {String} data.time - Время отображения в мск
 * @param {String} data.text - Текст
 * */
export function notify (data) {
  Vue.notify({
    position: 'top right',
    ...data,
    type: data.type || 'info',
    text: data.text
  })
}

/**
 * Негативное глобальное оповещение
 * @function
 * @param {String} message - Текст
 * @param {String} i18nArgs - Аргументы для i18n
 * */
export function notifyError (message, i18nArgs) {
  Vue.notify({
    position: 'top right',
    type: 'error',
    text: parseServerErrors(message, i18nArgs)
  })
}

/**
 * Позитивное глобальное оповещение
 * @function
 * @param {String} message - Текст
 * */
export function notifySuccess (message) {
  Vue.notify({
    position: 'top right',
    type: 'success',
    text: message
  })
}

export default {
  notify,
  notifyError,
  notifySuccess
}
