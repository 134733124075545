export default {
  'page not found': 'Pagina nu a fost găsită',
  submit: 'Trimite',
  'full name': 'nume complet',
  upload: 'încarcă',
  'new photo': 'fotografie nouă',
  'last name': 'nume',
  'first name': 'prenume',
  'middle name': 'patronimic',
  country: 'țară',
  city: 'oraș',
  interests: 'interese',
  'favourite products': 'produse preferate',
  achievements: 'realizări',
  save: 'salvează',
  saved: 'salvat',
  cancel: 'anulează',
  'edit profile': 'Editează profilul',
  logout: 'deconectare',
  email: 'e-mail',
  avatar: 'avatar',
  player: 'player',
  'admin panel': 'panoul de administrare',
  users: 'users',
  user: 'utilizator',
  create: 'creează',
  import: 'import',
  language: 'limba',
  'coral chips': 'chipsuri de corali',
  'broadcasting will be available soon': 'difuzarea va fi disponibilă mai târziu',
  'chat action not available': 'acțiunea nu este disponibilă'
}
