<template>
  <div class="embed-player">
    <div class="embed-player__emotions">
      <EmotionPanel />
    </div>

    <iframe
      :id="playerId"
      ref="iframe"
      allow="fullscreen"
      allowfullscreen
      @load="onLoad"
    />
  </div>
</template>

<script>
import { fcLoadIframe } from '@/components/Player/facecast'
import EventBus from '@/EventBus'
import EmotionPanel from '@/modules/EmotionPanel/index.ts.vue'

export default {
  components: {
    EmotionPanel
  },

  props: {
    playerId: {
      type: String,
      default: ''
    },
    streamLink: {
      type: String,
      default: ''
    }
  },

  methods: {
    async onLoad () {
      EventBus.emit('videoPlayer:iframeOnLoad', {
        streamLink: this.streamLink,
        day: this.$route.params?.day
      })

      if (!this.$refs?.iframe?.src) {
        fcLoadIframe(this.$refs.iframe, this.streamLink)
      }
    }
  }
}
</script>

<style lang="scss">
.embed-player {
  display: flex;
  height: 100%;

  &__emotions {
    position: absolute;
    right: 0;
    bottom: 60px;
  }

  iframe {
    width: 100%;
    border: unset;
  }
}
</style>
