import errors from './errors'
import success from './success'
import main from './main'
import vuetify from './vuetify'
// import intro from './intro'

export default {
  $vuetify: vuetify,
  ...main,
  errors,
  // intro,
  ...success
}
