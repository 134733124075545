import Vuex from 'vuex'
import Vue from 'vue'
import { sendEmoji } from '@/services/api/expertChat'

Vue.use(Vuex)

const store = new Vuex.Store({
  actions: {
    sendEmoji (context, emojiName) {
      return sendEmoji(emojiName)
    }
  }
})

export default store
