var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "v-step-" + _vm.hash,
      staticClass: "v-step",
      class: { "v-step--sticky": _vm.isSticky },
      attrs: { id: "v-step-" + _vm.hash }
    },
    [
      _vm._t("header", [
        _vm.step.header
          ? _c("div", { staticClass: "v-step__header" }, [
              _vm.step.header.title
                ? _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.step.header.title) }
                  })
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _vm._t("content", [
        _c("div", { staticClass: "v-step__content" }, [
          _vm.step.content
            ? _c("div", { domProps: { innerHTML: _vm._s(_vm.step.content) } })
            : _c("div", [
                _vm._v(
                  " This is a demo step! The id of this step is " +
                    _vm._s(_vm.hash) +
                    " and it targets " +
                    _vm._s(_vm.step.target) +
                    ". "
                )
              ])
        ])
      ]),
      _vm._t("actions", [
        _c("div", { staticClass: "v-step__buttons" }, [
          !_vm.isLast && _vm.isButtonEnabled("buttonSkip")
            ? _c(
                "button",
                {
                  staticClass: "v-step__button v-step__button-skip",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.skip($event)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.labels.buttonSkip) + " ")]
              )
            : _vm._e(),
          !_vm.isFirst && _vm.isButtonEnabled("buttonPrevious")
            ? _c(
                "button",
                {
                  staticClass: "v-step__button v-step__button-previous",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.previousStep($event)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.labels.buttonPrevious) + " ")]
              )
            : _vm._e(),
          !_vm.isLast && _vm.isButtonEnabled("buttonNext")
            ? _c(
                "button",
                {
                  staticClass: "v-step__button v-step__button-next",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.nextStep($event)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.labels.buttonNext) + " ")]
              )
            : _vm._e(),
          _vm.isLast && _vm.isButtonEnabled("buttonStop")
            ? _c(
                "button",
                {
                  staticClass: "v-step__button v-step__button-stop",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.finish($event)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.labels.buttonStop) + " ")]
              )
            : _vm._e()
        ])
      ]),
      _c("div", {
        staticClass: "v-step__arrow",
        class: {
          "v-step__arrow--dark": _vm.step.header && _vm.step.header.title
        },
        attrs: { "data-popper-arrow": "" }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }