import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { EN } from '@/constants/languages'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('@/locales', true, /index\.js/)
  const messages = {}
  locales.keys().forEach(key => {
    const locale = key.split('/')[1]
    messages[locale] = locales(key).default
  })
  return messages
}

/**
 * @param choice {number} индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} общее количество доступных вариантов
 * @returns финальный индекс для выбора соответственного варианта слова
 */
const slavicPluralizationRule = function (choice, choicesLength) {
  // this === VueI18n instance, so the locale property also exists here

  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1

  if (choicesLength < 4) {
    return (!teen && endsWithOne) ? 1 : 2
  }
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return (choicesLength < 4) ? 2 : 3
}

// Ключ - язык, для которого будет применяться правило, в этом примере - `'ru'`
// Value - функция плюрализации
const pluralizationRules = {
  ru: slavicPluralizationRule,
  ua: slavicPluralizationRule
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || EN,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || EN,
  messages: loadLocaleMessages(),
  pluralizationRules
})

export const locales = Object.keys(i18n.messages)

export default i18n
