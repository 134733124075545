export default {
  'page not found': 'Lehte ei leitud',
  submit: 'Esitama',
  'full name': 'täisnimi',
  upload: 'laadima',
  'new photo': 'uus foto',
  'last name': 'perekonnanimi',
  'first name': 'eesnimi',
  'middle name': 'isanimi',
  country: 'riik',
  city: 'linn',
  interests: 'huvi',
  'favourite products': 'lemmiktooted',
  achievements: 'saavutus',
  save: 'salvestama',
  saved: 'salvestama',
  cancel: 'tühistama',
  'edit profile': 'Profiili muutmine',
  logout: 'logout',
  email: 'Post',
  avatar: 'avatar',
  player: 'mängija',
  'admin panel': 'admin paneel',
  users: 'kasutaja',
  user: 'kasutaja',
  create: 'luua',
  import: 'import',
  language: 'keel',
  'coral chips': 'korallilaastud',
  'to vote': 'to vote',
  vote: 'vote',
  votes: 'votes',
  'chat action not available': 'toiming pole saadaval'
}
