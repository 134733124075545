var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-avatar",
    {
      attrs: { size: _vm.size, tile: _vm.tile, height: "100%", width: "100%" }
    },
    [
      _c("img", {
        staticStyle: { "object-fit": "cover", "object-position": "center" },
        attrs: { alt: _vm.alt, src: _vm.image },
        on: { error: _vm.handleError }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }