var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "header", class: { hide: _vm.isHideHeader } },
    [
      _c("v-container", { attrs: { fluid: "" } }, [
        _c(
          "div",
          {
            staticClass: "header__inner",
            class: { "header-light-theme": _vm.isLightTheme }
          },
          [
            _c("div", { staticClass: "header__logo" }, [
              _c("a", { staticClass: "logo", attrs: { href: "/" } }, [
                _c("img", {
                  attrs: {
                    src: _vm.isLightTheme
                      ? require("@/assets/img/svg/logo_cc.svg")
                      : require("@/assets/img/" +
                          _vm.themePath +
                          "/logo_cc.svg"),
                    alt: ""
                  }
                })
              ])
            ]),
            _c("v-spacer"),
            _vm.settings &&
            _vm.settings.eventDays &&
            _vm.settings.eventDays.enabled
              ? _c("div", { staticClass: "header__days" }, [
                  _c(
                    "div",
                    { staticClass: "t-days" },
                    _vm._l(_vm.eventDays, function(day, index) {
                      return _c(
                        "router-link",
                        {
                          key: day.id,
                          staticClass: "t-day",
                          class: {
                            "t-day--left": !index,
                            "t-day--middle":
                              index > 0 && index < _vm.eventDays.length - 1,
                            "t-day--right":
                              _vm.eventDays.length &&
                              _vm.eventDays.length - 1 === index
                          },
                          attrs: {
                            to: "/page/day/" + day.index,
                            "active-class": "active"
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "t-day__text text-uppercase" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("day")) + " " + _vm._s(day.index)
                              )
                            ]
                          ),
                          day.isDayStart
                            ? _c("div", { staticClass: "t-day-live" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/svg/live.svg"),
                                    alt: ""
                                  }
                                })
                              ])
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                ])
              : _vm._e(),
            _c("v-spacer"),
            _c("div", { staticClass: "header__nav" }, [
              _c("nav", { staticClass: "topmenu" }, [
                _c("ul", [
                  _vm.settings &&
                  _vm.settings.schedule &&
                  _vm.settings.schedule.enabled
                    ? _c(
                        "li",
                        [
                          _c("router-link", { attrs: { to: "/schedule" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("schedule")) + " ")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.settings &&
                  _vm.settings.nominations &&
                  _vm.settings.nominations.enabled
                    ? _c(
                        "li",
                        [
                          _c("router-link", { attrs: { to: "/nominations" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("new ranks")) + " ")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.settings && _vm.settings.help && _vm.settings.help.enabled
                    ? _c(
                        "li",
                        {
                          staticClass: "topmenu__help-link",
                          on: {
                            click: function($event) {
                              _vm.helpModal = true
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("help")) + " ")]
                      )
                    : _vm._e()
                ])
              ])
            ]),
            _c("v-spacer"),
            _c("div", { staticClass: "header__right" }, [
              _c("div", { staticClass: "header__right-user" }, [
                _vm.settings && _vm.settings["my-profile"].enabled
                  ? _c(
                      "div",
                      { staticClass: "user-panel" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "usp-link",
                            attrs: { to: "/my-profile" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "usp-link__img" },
                              [
                                _c("Avatar", {
                                  attrs: {
                                    small: "",
                                    tile: "",
                                    src: _vm.user.avatar && _vm.user.avatar.url,
                                    alt: "avatar " + _vm.user.lastName
                                  }
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "usp-link__text-block" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "usp-link__text text-uppercase"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.user.firstName) +
                                      " " +
                                      _vm._s(
                                        _vm.user.lastName &&
                                          _vm.user.lastName[0]
                                      ) +
                                      ". "
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "usp-points" }, [
                              _vm._v(" 137 корал-фишек ")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "user-logout cursor-pointer",
                    on: { click: _vm.openLogoutDialog }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.isLightTheme
                          ? require("@/assets/img/svg/logout.svg")
                          : require("@/assets/img/" +
                              _vm.themePath +
                              "/logout.svg"),
                        title: _vm.$t("logout"),
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c(
                "div",
                { staticClass: "header__right-dropdown" },
                [
                  _c("LangSelector", {
                    key: "langSelector",
                    attrs: { languages: _vm.languages },
                    model: {
                      value: _vm.language,
                      callback: function($$v) {
                        _vm.language = $$v
                      },
                      expression: "language"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "header__right-days" }, [
                _c("div", { staticClass: "topline-days-panel" }, [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.hideDaysDropdown,
                          expression: "hideDaysDropdown"
                        }
                      ],
                      staticClass: "current-day text-uppercase cursor-pointer",
                      class: { "current-day--light": _vm.isMenuOpened },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.toggleDays($event)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("day")) + " ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "topline-days-dropdown",
                      class: { active: _vm.isDaysOpened }
                    },
                    _vm._l(_vm.eventDays, function(day) {
                      return _c(
                        "router-link",
                        {
                          key: day.id,
                          staticClass: "day-link text-uppercase cursor-pointer",
                          attrs: { to: "/page/day/" + day.index }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("day")) +
                              " " +
                              _vm._s(day.index) +
                              " "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "header__right-menu" }, [
                _c("div", { staticClass: "mobile-menu-block" }, [
                  _c(
                    "a",
                    {
                      staticClass: "mobile-menu-btn",
                      class: { active: _vm.isMenuOpened },
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.isMenuOpened = !_vm.isMenuOpened
                        }
                      }
                    },
                    [_c("span")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mobile-menu-panel-container",
                      class: { active: _vm.isMenuOpened }
                    },
                    [
                      _c("v-expand-transition", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isMenuOpened,
                                expression: "isMenuOpened"
                              }
                            ],
                            staticClass: "mobile-menu-panel"
                          },
                          [
                            _c("div", { staticClass: "mobile-menu__header" }, [
                              _c(
                                "div",
                                { staticClass: "header__inner" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "header__logo" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "logo",
                                          attrs: { to: "/" }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.isMenuOpened
                                                ? require("@/assets/img/theme/logo_cc.svg")
                                                : require("@/assets/img/" +
                                                    _vm.themePath +
                                                    "/logo_cc.svg"),
                                              alt: ""
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c("div", { staticClass: "header__days" }, [
                                    _c(
                                      "div",
                                      { staticClass: "t-days" },
                                      _vm._l(_vm.eventDays, function(
                                        day,
                                        index
                                      ) {
                                        return _c(
                                          "router-link",
                                          {
                                            key: day.id,
                                            staticClass: "t-day",
                                            class: {
                                              "t-day--left": !index,
                                              "t-day--right":
                                                _vm.eventDays.length &&
                                                _vm.eventDays.length - 1 ===
                                                  index,
                                              active: day.isDayStart
                                            },
                                            attrs: {
                                              to: "/page/day/" + day.index
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "t-day__text text-uppercase"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("day")) +
                                                    " " +
                                                    _vm._s(day.index)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "t-day-live" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: require("@/assets/img/svg/live.svg"),
                                                    alt: ""
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "fm-live t-day-fm-live"
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: require("@/assets/img/svg/live.svg"),
                                                    alt: ""
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ]),
                                  _c("v-spacer"),
                                  _c("div", { staticClass: "header__nav" }, [
                                    _c("nav", { staticClass: "topmenu" }, [
                                      _c("ul", [
                                        _c(
                                          "li",
                                          [
                                            _c(
                                              "router-link",
                                              { attrs: { to: "/schedule" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("schedule")) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "li",
                                          [
                                            _c(
                                              "router-link",
                                              { attrs: { to: "/nominations" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("new ranks")
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "li",
                                          {
                                            on: {
                                              click: function($event) {
                                                _vm.helpModal = true
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("help")) + " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c("v-spacer"),
                                  _c("div", { staticClass: "header__right" }, [
                                    _c(
                                      "div",
                                      { staticClass: "header__right-user" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "user-panel" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "usp-link",
                                                attrs: { to: "/my-profile" }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "usp-link__img"
                                                  },
                                                  [
                                                    _c("Avatar", {
                                                      attrs: {
                                                        tile: "",
                                                        src:
                                                          _vm.user.avatar &&
                                                          _vm.user.avatar.url,
                                                        alt:
                                                          "avatar " +
                                                          _vm.user.lastName
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "usp-link__text-block"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "usp-link__text text-uppercase"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.user.firstName
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.user
                                                                .lastName &&
                                                                _vm.user
                                                                  .lastName[0]
                                                            ) +
                                                            ". "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "usp-points" },
                                                  [_vm._v(" 137 корал-фишек ")]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "user-logout cursor-pointer",
                                            on: { click: _vm.openLogoutDialog }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/" +
                                                  _vm.themePath +
                                                  "/logout.svg"),
                                                title: _vm.$t("logout"),
                                                alt: "logout"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "header__right-dropdown" },
                                      [
                                        _c("LangSelector", {
                                          key: "langSelector",
                                          attrs: { languages: _vm.languages },
                                          model: {
                                            value: _vm.language,
                                            callback: function($$v) {
                                              _vm.language = $$v
                                            },
                                            expression: "language"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "header__right-days" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "topline-days-panel" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "current-day text-uppercase cursor-pointer",
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.toggleDays(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("day")) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "topline-days-dropdown",
                                                class: {
                                                  active: _vm.isDaysOpened
                                                }
                                              },
                                              _vm._l(_vm.eventDays, function(
                                                day
                                              ) {
                                                return _c(
                                                  "router-link",
                                                  {
                                                    key: day.id,
                                                    staticClass:
                                                      "day-link text-uppercase cursor-pointer",
                                                    attrs: {
                                                      to:
                                                        "/page/day/" + day.index
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.$t("day")) +
                                                        " " +
                                                        _vm._s(day.index) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              }),
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "header__right-menu" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "mobile-menu-block" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "mobile-menu-btn",
                                                class: {
                                                  active: _vm.isMenuOpened
                                                },
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    _vm.isMenuOpened = !_vm.isMenuOpened
                                                  }
                                                }
                                              },
                                              [_c("span")]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "mobile-menu-forum-logo-block" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "mobile-menu-forum-logo" },
                                  [
                                    _c("c-img", {
                                      attrs: {
                                        src:
                                          "assets/img/theme/event-logo-light.png",
                                        alt: ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c("nav", { staticClass: "mobile-menu" }, [
                              _c("ul", [
                                _c(
                                  "li",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "text-uppercase",
                                        attrs: { to: "/schedule" }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("schedule")) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "li",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "text-uppercase",
                                        attrs: { to: "/nominations" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("new ranks")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "mobile-menu-actions" }, [
                              _c("div", { staticClass: "mobile-menu-help" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-uppercase cursor-pointer",
                                    on: {
                                      click: function($event) {
                                        _vm.helpModal = true
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(_vm.$t("help")) + " ")]
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "user-logout cursor-pointer",
                                  on: { click: _vm.openLogoutDialog }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/theme/logout.svg"),
                                      title: _vm.$t("logout"),
                                      alt: ""
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "mobile-menu-lang-block" },
                                [
                                  _c("LangSelector", {
                                    key: "langSelector",
                                    attrs: { languages: _vm.languages },
                                    model: {
                                      value: _vm.language,
                                      callback: function($$v) {
                                        _vm.language = $$v
                                      },
                                      expression: "language"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("div", { staticClass: "mm-tr-text-block" }, [
                              _c("div", { staticClass: "mm-tr-text" }, [
                                _vm.activeEvent
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.activeEvent.title))
                                    ])
                                  : _vm._e()
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "mm-account-link-block" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "mm-account-link",
                                    attrs: { to: "/my-profile" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mm-account-link__img" },
                                      [
                                        _c("Avatar", {
                                          attrs: {
                                            tile: "",
                                            src:
                                              _vm.user.avatar &&
                                              _vm.user.avatar.url,
                                            alt: "avatar " + _vm.user.lastName
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mm-account-link__name" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.user.firstName) +
                                            " " +
                                            _vm._s(
                                              _vm.user.lastName &&
                                                _vm.user.lastName[0]
                                            ) +
                                            ". "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ])
              ])
            ])
          ],
          1
        )
      ]),
      _c(
        "modal",
        {
          attrs: { logo: "", "btn-left": "logout", "btn-rigth": "cancel" },
          on: {
            btnRigthClick: _vm.closeModal,
            btnLeftClick: _vm.actionLogout,
            onCloseModal: function($event) {
              _vm.open = false
            }
          },
          model: {
            value: _vm.open,
            callback: function($$v) {
              _vm.open = $$v
            },
            expression: "open"
          }
        },
        [
          _c("template", { slot: "content" }, [
            _vm._v(" " + _vm._s(_vm.$t("are you sure want to logout")) + " ")
          ])
        ],
        2
      ),
      _c(
        "modal",
        {
          attrs: { "max-height": 400, logo: "" },
          on: {
            onCloseModal: function($event) {
              _vm.helpModal = false
            }
          },
          model: {
            value: _vm.helpModal,
            callback: function($$v) {
              _vm.helpModal = $$v
            },
            expression: "helpModal"
          }
        },
        [_c("template", { slot: "content" }, [_c("HelpHTML")], 1)],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }