var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    _vm._g(
      _vm._b({ staticClass: "v-dialog" }, "v-dialog", _vm.$attrs, false),
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "modal" }, [
        _c(
          "div",
          { staticClass: "modal-close", on: { click: _vm.onCloseClick } },
          [_c("v-icon", { attrs: { large: "" } }, [_vm._v(" mdi-close ")])],
          1
        ),
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _vm.title.length
              ? _c("div", { staticClass: "modal-header__title" }, [
                  _vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " ")
                ])
              : _vm._e(),
            _c("c-img", {
              staticClass: "modal__top-element",
              attrs: { src: "/assets/img/theme/modal-top-element.png", alt: "" }
            }),
            _c("c-img", {
              staticClass: "modal__bottom-element",
              attrs: {
                src: "/assets/img/theme/modal-bottom-element.png",
                alt: ""
              }
            }),
            _vm.logo
              ? _c(
                  "div",
                  { staticClass: "modal-header__logo" },
                  [
                    _c("c-img", {
                      staticClass: "modal-header-logo",
                      attrs: {
                        src:
                          "/assets/img/" +
                          _vm.themePath +
                          "/event-logo-light.png",
                        alt: "logo"
                      }
                    }),
                    _vm.isForum22
                      ? _c("c-img", {
                          staticClass: "modal-header-logo modal-header-logo--2",
                          attrs: {
                            src: "/assets/img/theme-forum-22/logo-2.png",
                            alt: "logo"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "modal-body", style: _vm.maxHeightStyle },
          [
            _c(
              "div",
              { staticClass: "modal-body__content" },
              [_vm._t("content")],
              2
            ),
            _vm._t("default", [
              _vm.btnLeft.length || _vm.btnRigth.length
                ? _c("div", { staticClass: "modal-body__buttons" }, [
                    _vm.btnLeft.length
                      ? _c(
                          "div",
                          {
                            staticClass: "button button--left",
                            on: { click: _vm.btnLeftClick }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("upperFirst")(_vm.$t(_vm.btnLeft))
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.btnRigth.length
                      ? _c(
                          "div",
                          {
                            staticClass: "button button--rigth",
                            on: { click: _vm.btnRigthClick }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("upperFirst")(_vm.$t(_vm.btnRigth))
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }