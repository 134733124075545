export default {
  'page not found': 'Nie znaleziono strony',
  submit: 'Submit',
  'full name': 'imię i nazwisko',
  upload: 'upload',
  'new photo': 'nowe zdjęcie',
  'last name': 'nazwisko',
  'first name': 'imię',
  'middle name': 'patronimik',
  country: 'Państwo',
  city: 'miasto',
  interests: 'zainteresowania',
  'favourite products': 'ulubione produkty',
  achievements: 'osiągnięcia',
  save: 'Zapisz',
  saved: 'saved',
  cancel: 'anuluj',
  'edit profile': 'Edytuj Profil',
  logout: 'Wyloguj się',
  email: 'email',
  avatar: 'avatar',
  player: 'gracz',
  'admin panel': 'panel administracyjny',
  users: 'użytkownicy',
  user: 'użytkownik',
  create: 'Utwórz',
  import: 'import',
  language: 'język',
  'coral chips': 'wiórki koralowe',
  'broadcasting will be available soon': 'la trasmissione sarà disponibile più tardi',
  'chat action not available': 'akcja niedostępna'
}
