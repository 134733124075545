var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "img",
    _vm._g(
      _vm._b(
        {
          attrs: { alt: _vm.alt, src: _vm.getLinkByEvent(_vm.src) },
          on: { error: _vm.handleError }
        },
        "img",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }