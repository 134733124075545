import Vuex from 'vuex'
import Vue from 'vue'
import * as apiProfile from '@/services/api/profile'
import * as apiMedia from '@/services/api/media'
import { formDataFromBlobUrl } from '@/utils/file'
import persistedStorage from './persistedStorage'
import { cloneDeep, isEqual } from 'lodash'
import EventBus from '@/EventBus'
import { getLinkByFileId } from '@/utils/mediaHelpers'

class User {
  /**
   * @type {Object}
   * */
  avatar = {
    /**
     * @type {String}
     * */
    id: null,
    /**
     * @type {String}
     * */
    url: null
  }

  /**
   * @type {String}
   * */
  firstName = null
  /**
   * @type {String}
   * */
  lastName = null
  /**
   * @type {String}
   * */
  email = null
  /**
   * @type {String}
   * */
  id = null
  /**
   * @type {String}
   * */
  login = null
  /**
   * @type {String}
   * */
  password = null
  /**
   * @type {String}
   * */
  languageId = null

  constructor (props) {
    if (props) {
      this.avatar = props.avatar
      this.firstName = props.firstName
      this.lastName = props.lastName
      this.email = props.email
      this.id = props.id
      this.login = props.login
      this.password = props.password
      this.languageId = props.languageId
    }
  }

  /**
   * @type {String}
   * */
  get fullName () {
    return `${this.firstName || ''} ${this.lastName || ''}`
  }
}

Vue.use(Vuex)

export const defaultState = () => ({
  user: new User()
})

const initialState = () => {
  const user = persistedStorage.getUser()
  if (user?.id) {
    return {
      user
    }
  }
  return defaultState()
}

export default new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',

  state: initialState(),

  getters: {
    /**
     * @return {Function}
     * */
    deserialize: () =>
      /**
       * @param {object} user
       * @return {UserInstance}
       * */
      (user) => ({
        avatar: {
          id: user.avatar,
          url: user.avatar && getLinkByFileId(user.avatar)
        },
        email: user.email,
        firstName: user.full_name?.split(' ')?.[0],
        lastName: user.full_name?.split(' ')?.[1],
        get fullName () {
          return `${this.firstName || ''} ${this.lastName || ''}`
        },
        id: user.id,
        login: user.login,
        password: user.password,
        languageId: user.language_id,
        customizedMaterials: user.customized_materials
      }),

    serialize: () => (user) => ({
      avatar: user.avatar.id,
      email: user.email,
      full_name: `${user.firstName || ''} ${user.lastName || ''}`,
      id: user.id,
      login: user.login,
      lang: user.lang,
      password: user.password,
      language_id: user.languageId
    })
  },

  actions: {
    initUser ({ commit, state }, payload) {
      commit('updateUser', payload)

      EventBus.emit('user:init', cloneDeep(state.user))

      return cloneDeep(state.user)
    },

    async getUser ({ commit, getters, state }) {
      const { data: user } = await apiProfile.getProfile()
      const deserializedUser = getters.deserialize(user)

      commit('updateUser', deserializedUser)

      return cloneDeep(state.user)
    },

    async updateUser ({ commit, getters, state }, patch) {
      if (!patch) return

      const user = {
        ...cloneDeep(state.user),
        ...patch
      }

      if (!user.avatar?.id && user.avatar.url) {
        const avatarFormData = await formDataFromBlobUrl(user.avatar.url)
        const { data } = await apiMedia.uploadFile(avatarFormData)
        user.avatar.id = data.id
        user.avatar.url = getLinkByFileId(data.id)
      }

      await apiProfile.updateProfile(getters.serialize(user))
      commit('updateUser', user)

      return cloneDeep(state.user)
    },

    /**
     * @param {object} context
     * @param {Language} language
     * */
    async updateUserLanguage ({ dispatch, state }, language) {
      if (typeof language !== 'object' || !language.code) throw new Error('lang must be object')
      if (!state.user?.id) return

      return dispatch('updateUser', {
        languageId: language.id
      })
    },

    logout ({ commit }) {
      commit('resetState')
    }
  },

  mutations: {
    updateUser (state, payload) {
      const newUser = {
        ...state.user,
        ...payload
      }

      if (isEqual(state, newUser)) return
      state.user = newUser
      persistedStorage.updateUser(newUser)
      EventBus.emit('user:update', Object.freeze(cloneDeep(newUser)))
    },

    resetState (state) {
      const cleanState = defaultState()
      Object.entries(cleanState).forEach(([key, val]) => {
        Vue.set(state, key, val)
      })

      EventBus.emit('user:update', null)
    }
  }
})
