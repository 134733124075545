export default {
  'page not found': 'Stránka nebyla nalezena',
  submit: 'Předložit',
  'full name': 'příjmení',
  upload: 'nahrávání',
  'new photo': 'fotka',
  'last name': 'příjmení',
  'first name': 'jméno',
  'middle name': 'příjmení',
  country: 'zem',
  city: 'město',
  interests: 'zájem',
  'favourite products': 'oblíbené produkty',
  achievements: 'úspěch',
  save: 'uložit',
  saved: 'spást',
  cancel: 'zrušit',
  'edit profile': 'Upravit profil',
  logout: 'odhlášení',
  email: 'mail',
  avatar: 'Miniatura',
  player: 'přehrávač',
  'admin panel': 'admin panel',
  users: 'uživatel',
  user: 'uživatel',
  create: 'vytvořit',
  import: 'dovoz',
  language: 'jazyk',
  'coral chips': 'coral chips',
  'chat action not available': 'a művelet nem érhető el'
}
