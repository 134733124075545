import apiClient from '@/services/api/apiClient'
import { MEDIA_PREFIX } from '@/constants/apiPrefixes'

/**
 * @param {FormData} formData
 * @param {File} formData.file
 * */
export const uploadFile = (formData) => apiClient.post(`${MEDIA_PREFIX}/upload`, formData)

export const downloadFile = (uuid) => apiClient.get(`${MEDIA_PREFIX}/download/${uuid}`)
