import i18n from './i18n'
import Vue from 'vue'
import { upperFirst } from 'lodash'

Vue.filter('$t', val => val ? i18n.t(val.toLocaleLowerCase()) : '')

Vue.filter('upperFirst', val => val ? upperFirst(val) : '')

// eslint-disable-next-line no-console
Vue.filter('log', val => { console.log(val) })
