import Centrifuge from 'centrifuge'
import authService from '@/services/auth'
const parseName = (name) => {
  const splited = name.split(':')
  return [splited[0], splited.slice(1).join(':')]
}

class EventBus {
  subscribers = {}

  constructor () {
    this.centrifuge = new Centrifuge(process.env.VUE_APP_BASE_CENTRIFUGO_URL)

    const centrifugeToken = authService.getAccess().centrifugoToken
    if (centrifugeToken) {
      this.connectCentrifuge(centrifugeToken)
    }

    this.onUserUpdate = this.onUserUpdate.bind(this)

    this.subscribe('user:update', this.onUserUpdate)

    this.centrifuge.on('connect', function (connectCtx) {
      console.info('connected', connectCtx)
    })
  }

  onUserUpdate (user) {
    if (user) {
      const centrifugeToken = authService.getAccess().centrifugoToken
      this.connectCentrifuge(centrifugeToken)
    } else {
      this.centrifuge.setToken(null)
      this.centrifuge.disconnect()
    }
  }

  connectCentrifuge (token) {
    this.centrifuge.setToken(token)
    this.centrifuge.connect()
  }

  subscribe (name, handler) {
    const [entity, action] = parseName(name)

    if (!this.subscribers[entity]) {
      this.subscribers[entity] = {}
    }

    if (!this.subscribers[entity][action]) {
      this.subscribers[entity][action] = new Map()
    }

    const subscriber = { handler }

    if (entity === 'ws') {
      subscriber.centrifugeSubscription = this.centrifuge.subscribe(action, handler)
    }

    this.subscribers[entity][action].set(handler, subscriber)
  }

  unsubscribe (name, handler) {
    const [entity, action] = parseName(name)
    const actionSubscribers = this.subscribers[entity]?.[action]
    if (!actionSubscribers) return

    if (entity === 'ws') {
      const subscriber = actionSubscribers.get(handler)
      if (subscriber) {
        const centrifugeSubscription = actionSubscribers.get(handler).centrifugeSubscription
        if (centrifugeSubscription) centrifugeSubscription.unsubscribe()
      }
    }

    actionSubscribers.delete(handler)
  }

  emit (name, data) {
    const [entity, action] = parseName(name)
    const actionSubscribers = this.subscribers[entity]?.[action]
    if (!actionSubscribers) return

    actionSubscribers.forEach(el => el.handler(data))
  }
}

const EventBusInstance = new EventBus()

export default EventBusInstance
