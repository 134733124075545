<template>
  <v-app class="layout-main">
    <!--<v-system-bar app></v-system-bar>-->

    <!--<v-app-bar app absolute></v-app-bar>-->
    <Header
      :is-hide-header="isHideHeader"
      :user="user"
      :is-light-theme="isHeaderLight"
    />

    <!--<v-navigation-drawer app></v-navigation-drawer>-->

    <!-- Sizes your content based upon application components -->
    <v-main v-if="selectedEvent">
      <!-- If using vue-router -->
      <router-view />
    </v-main>

    <!--<v-navigation-drawer app right></v-navigation-drawer>-->

    <!--<v-footer app></v-footer>-->
    <Footer :is-light-theme="isFooterLight" />

    <!--<v-bottom-navigation app></v-bottom-navigation>-->

    <v-overlay :value="!selectedEvent" />
  </v-app>
</template>

<script>
import Header from '@/modules/LayoutMain/containers/Header'
import Footer from '@/modules/LayoutMain/containers/Footer'
import userService from '@/services/user'
import vuetify from '@/plugins/vuetify'
import businessEventService from '@/services/businessEvent'
import EventBus from '@/EventBus'
import { THEME_FORUM_22 } from '@/constants/events'

export default {
  name: 'MainLayout',
  vuetify,

  components: {
    Header,
    Footer
  },

  data: () => ({
    selectedEvent: null,
    currentStaticEvent: {},
    user: {}
  }),

  computed: {
    isHeaderLight () {
      return this.currentStaticEvent.name === THEME_FORUM_22 &&
      (this.$route.path.split('/').includes('my-profile') ||
      this.$route.path.split('/').includes('profile-nominee') ||
      this.$route.path.split('/').includes('profile-speaker'))
    },

    isFooterLight () {
      return this.currentStaticEvent.name === THEME_FORUM_22 &&
      (this.$route.path.split('/').includes('day') || this.$route.path.split('/').includes('my-profile'))
    },

    isHideHeader () {
      return this.currentStaticEvent.name === THEME_FORUM_22 &&
      this.$route.path.split('/').includes('profile-speaker')
    }
  },

  async created () {
    this.selectedEvent = await businessEventService.getActiveEvent()
    this.user = await userService.getUser()
    this.setActiveEventSelector()
    this.currentStaticEvent = await businessEventService.getStaticEvent()

    EventBus.subscribe('user:update', user => {
      if (!user) {
        this.user = {}
        return
      }
      this.user = user
    })
  },

  methods: {
    setActiveEventSelector () {
      document.documentElement.classList.add(`event-${this.selectedEvent.id}`)
    }
  }
}
</script>

<style lang="scss">
@import "~@/styles/variables";
@import "~vuetify/src/styles/styles";

.layout-main {
  padding: 15px;

  @include below(map_get($grid-breakpoints, md)) {
    padding: 10px;
  }

  .v-main__wrap {
    display: flex;
    flex-direction: column;
  }
}
</style>
