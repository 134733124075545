import apiClient from './apiClient'
import { AUTH_PREFIX } from '@/constants/apiPrefixes'

export const getUserById = (id) => apiClient.get(`${AUTH_PREFIX}/user/${id}`)

export const getUsers = () => apiClient.get(`${AUTH_PREFIX}/users`)

export const getRoles = () => apiClient.get(`${AUTH_PREFIX}/roles`)

export const createUser = (user) => apiClient.post(`${AUTH_PREFIX}/user`, user)

export const updateUser = (user) => apiClient.patch(`${AUTH_PREFIX}/user/${user.id}`, user)

export const deleteUser = (id) => apiClient.delete(`${AUTH_PREFIX}/user/${id}`)

export const deleteMember = (id) => apiClient.delete(`${AUTH_PREFIX}/user/${id}`)

/**
 * Импорт пользователей из файла
 * @param {Object} formData
 * @param {File} formData.file
 * @param {string} formData.eventId
 * */
export const importUsers = ({ file, eventId }) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('event_id', eventId)
  return apiClient.post(`${AUTH_PREFIX}/user/import/csv`, formData)
}
