var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("notifications"),
      _vm.$route.name ? _c(_vm.layoutName, { tag: "component" }) : _vm._e(),
      _vm._l(_vm.daysList, function(item, index) {
        return _c("Player", {
          key: item.id + index + item.day + item.languageId,
          attrs: {
            "player-id": item.id,
            "stream-link": item.streamLink,
            "is-plug": item.isPlug,
            "plug-link": item.plugLink,
            "plug-type": item.plugType,
            day: item.day
          }
        })
      }),
      _c("v-tour", {
        attrs: {
          name: "tour",
          callbacks: _vm.tourCallbacks,
          steps: _vm.steps,
          options: _vm.tourOptions
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }