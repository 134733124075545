<template>
  <div>
    <keep-alive>
      <component :is="componentToShow" />
    </keep-alive>
  </div>
</template>

<style lang="scss" scoped>
    div {
      white-space: pre;
      font-size: 16px;
      font-family: "Roboto", serif;
      text-transform: none;
      font-style: normal;
      width: 256px;
      line-height: 42px;
      font-weight: normal;

      @include below(map_get($grid-breakpoints, sm)) {
        white-space: normal;
        font-size: 21px;
      }

      a {
        padding: 1px;

        @include below(map_get($grid-breakpoints, sm)) {
          margin-right: 20px;
        }
      }
    }
</style>
<script>
export default {
  computed: {
    componentToShow () {
      switch (true) {
        case (this.$i18n.locale === 'en'):
          return () => import('./components/en')

        case (this.$i18n.locale === 'ru'):
          return () => import('./components/ru')

        case (this.$i18n.locale === 'de'):
          return () => import('./components/de')

        case (this.$i18n.locale === 'bg'):
          return () => import('./components/bg')

        case (this.$i18n.locale === 'it'):
          return () => import('./components/it')

        case (this.$i18n.locale === 'ro'):
          return () => import('./components/ro')

        case (this.$i18n.locale === 'pl'):
          return () => import('./components/pl')

        case (this.$i18n.locale === 'es'):
          return () => import('./components/es')

        case (this.$i18n.locale === 'hu'):
          return () => import('./components/hu')
        default:
          return () => import('./components/en')
      }
    }
  }
}
</script>
