const s3images = [
  { name: '--auth-form-header-bg', image: 'assets/img/theme/auth-form-header-bg.png' },
  { name: '--banner1-bg', image: 'assets/img/theme/banner1-bg.png' },
  { name: '--banner1-image', image: 'assets/img/theme/banner1-image.png' },
  { name: '--banner2-bg', image: 'assets/img/theme/banner2-bg.jpg' },
  { name: '--event-logo', image: 'assets/img/theme/event-logo.png' },
  { name: '--event-logo-light', image: 'assets/img/theme/event-logo-light.png' },
  { name: '--information-bg', image: 'assets/img/theme/information-bg.png' },
  { name: '--knowledge-element', image: 'assets/img/theme/knowledge-element.png' },
  { name: '--modal-bottom-element', image: 'assets/img/theme/modal-bottom-element.png' },
  { name: '--modal-top-element', image: 'assets/img/theme/modal-top-element.png' },
  { name: '--new-ranks-element', image: 'assets/img/theme/new-ranks-element.png' },
  { name: '--new-ranks-element-2', image: 'assets/img/theme/new-ranks-element-2.png' },
  { name: '--new-ranks-element-3', image: 'assets/img/theme/new-ranks-element-3.png' },
  { name: '--presentation-plug', image: 'assets/img/theme/presentation-plug.jpg' },
  { name: '--eye', image: 'assets/img/svg/eye.svg' },
  { name: '--globe-svg', image: 'assets/img/svg/globe.svg' },
  { name: '--mail-svg', image: 'assets/img/svg/mail.svg' },
  { name: '--useful-material-container-bg', image: 'assets/img/theme/useful-material-container-bg.jpg' },
  { name: '--useful-material-element-1', image: 'assets/img/theme/useful-material-element-1.png' },
  { name: '--useful-material-element-2', image: 'assets/img/theme/useful-material-element-2.png' },
  { name: '--mp-schedule-item-bg_active', image: 'assets/img/theme/mp-schedule-item-bg_active.jpg' },
  { name: '--btn-background', image: 'assets/img/theme/btn-background.png' },
  { name: '--header-bg-mob', image: 'assets/img/theme/header-bg-mob.png' },
  { name: '--btn-mob', image: 'assets/img/theme/btn-mob.png' },
  { name: '--modal-header-bg', image: 'assets/img/theme/modal-header-bg-2.png' },
  { name: '--modal-left-button-bg', image: 'assets/img/theme/modal-btn-bg-2.png' },
  { name: '--schedule-day-now', image: 'assets/img/theme/now-day-bg.png' },
  { name: '--test-header', image: 'assets/img/theme/modal-header-bg.png' },
  { name: '--test-button', image: 'assets/img/theme/modal-btn-bg-1.png' },
  { name: '--arrow-down', image: 'assets/img/svg/arrow-down.svg' },
  { name: '--header-content-bg', image: 'assets/img/theme/header-content-bg.jpg' },
  { name: '--mobile-menu-panel-bg', image: 'assets/img/theme/mobile-menu-panel-bg.jpg' },
  { name: '--person-plug', image: 'assets/img/theme/person-plug.png' },
  { name: '--gray-circle-70', image: 'assets/img/theme/gray-circle-70.png' },
  { name: '--gray-circle-160', image: 'assets/img/theme/gray-circle-160.png' },
  { name: '--rank-wrapper--diamond-director-bg', image: 'assets/img/theme/rank-wrapper--diamond-director-bg.jpg' },
  { name: '--crown-empty-white', image: 'assets/img/svg/crown-empty-white.svg' },
  { name: '--crown-empty', image: 'assets/img/theme/crown-empty.svg' },
  { name: '--double-silver-masters-element', image: 'assets/img/theme/double-silver-masters-element.png' },
  { name: '--hero-image', image: 'assets/img/theme/hero-image.png' }

]

export default s3images
