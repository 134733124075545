import apiClient from './apiClient'
import { AUTH_PREFIX } from '@/constants/apiPrefixes'

export const getEmployeeById = (id) => apiClient.get(`${AUTH_PREFIX}/staff/${id}`)

export const getRoles = () => apiClient.get(`${AUTH_PREFIX}/roles`)

export const getEmployees = () => apiClient.get(`${AUTH_PREFIX}/staffs`)

export const createEmployee = (user) => apiClient.post(`${AUTH_PREFIX}/staff`, user)

export const updateEmployee = (user) => apiClient.patch(`${AUTH_PREFIX}/staff/${user.id}`, user)

export const deleteEmployee = (id) => apiClient.delete(`${AUTH_PREFIX}/staff/${id}`)
