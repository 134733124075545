const PREFIX = process.env.VUE_APP_ACCESS_PREFIX
const USER_KEY = PREFIX ? `${PREFIX}:user` : 'user'

const subscribers = []

/**
 * Записать пользователя в localStorage
 * @param {Object} data
 * @param {String} data.login
 * @param {String} data.id
 * @param {String} data.eventId
 * @param {String} data.email
 * @param {String} data.fullName
 * @param {String} data.avatar
 * */
export const setUser = (data) => {
  let user = data
  if (typeof user !== 'object') {
    try {
      user = JSON.parse(data)
    } catch {
      throw new TypeError('argument must be object or JSON string')
    }
  }
  localStorage.setItem(USER_KEY, JSON.stringify(user))
  subscribers.forEach(cb => cb(user))
}

export const getUser = () => JSON.parse(localStorage.getItem(USER_KEY))

/**
 * Записать пользователя в localStorage
 * @param {Object} data
 * @param {String} [data.login]
 * @param {String} [data.id]
 * @param {String} [data.eventId]
 * @param {String} [data.email]
 * @param {String} [data.fullName]
 * @param {String} [data.avatar]
 * */
export const updateUser = (data) => {
  setUser({
    ...getUser(),
    ...data
  })
}

export const removeUser = () => {
  localStorage.removeItem(USER_KEY)
}

export default {
  setUser,
  updateUser,
  getUser,
  removeUser
}
