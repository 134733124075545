export const SETTINGS_MAIN_KEY = 'main'
export const SETTINGS_HEADER_FOOTER_KEY = 'header-footer'
export const SETTINGS_HOME_PAGE_KEY = 'home page'

export const settings = [
  {
    elements: [
      {
        enabled: false,
        name: 'home',
        relation: null,
        title: 'Главная'
      },
      {
        enabled: false,
        name: 'schedule',
        relation: null,
        title: 'Расписание / Спикеры'
      },
      {
        enabled: false,
        name: 'nominations',
        relation: null,
        title: 'Новые ранги'
      },
      {
        enabled: false,
        name: 'my-profile',
        relation: null,
        title: 'Профиль'
      }
    ],
    name: 'main',
    title: 'Общее'
  },
  {
    elements: [
      {
        enabled: false,
        name: 'test',
        relation: null,
        title: 'Тесты'
      },
      {
        enabled: false,
        name: 'schedule',
        relation: 'main-schedule',
        title: 'Расписание'
      },
      {
        enabled: false,
        name: 'speakers',
        relation: 'main-schedule',
        title: 'Спикеры'
      },
      {
        enabled: false,
        name: 'nominations',
        relation: 'main-nominations',
        title: 'Новые ранги'
      },
      {
        enabled: false,
        name: 'materials',
        relation: null,
        title: 'Полезные материалы'
      }
    ],
    name: 'home page',
    title: 'Главная страница'
  },
  {
    elements: [
      {
        enabled: false,
        name: 'eventDays',
        relation: 'main-home',
        title: 'Дни мероприятия'
      },
      {
        enabled: false,
        name: 'schedule',
        relation: 'main-schedule',
        title: 'Расписание'
      },
      {
        enabled: false,
        name: 'nominations',
        relation: 'main-nominations',
        title: 'Новые ранги'
      },
      {
        enabled: false,
        name: 'help',
        relation: null,
        title: 'Помощь'
      },
      {
        enabled: false,
        name: 'my-profile',
        relation: null,
        title: 'Профиль'
      }
    ],
    name: 'header-footer',
    title: 'Хедер / Футер'
  }
]

export default {
  SETTINGS_HEADER_FOOTER_KEY,
  SETTINGS_HOME_PAGE_KEY,
  SETTINGS_MAIN_KEY,
  settings
}
