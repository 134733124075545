<template>
  <div class="embed-player">
    <img
      v-if="plugType === 'image'"
      :src="plugLink"
      class="embed-player__plug-image"
      alt="plug"
    >
    <iframe
      v-else
      :src="plugLink"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  </div>
</template>

<script>
import EventBus from '@/EventBus'

export default {
  props: {
    plugLink: {
      type: String,
      default: ''
    },
    plugType: {
      type: String,
      default: ''
    }
  },
  updated () {
    this.emitData()
  },
  mounted () {
    this.emitData()
  },
  methods: {
    emitData () {
      const day = this.$route.params?.day
      EventBus.emit('videoPlayer:show', day)
    }
  }
}
</script>

<style lang="scss">
.embed-player {
  display: flex;
  height: 100%;

  &__emotions {
    position: absolute;
    right: 0;
    bottom: 60px;
  }

  iframe {
    width: 100%;
    border: unset;
  }

  .embed-player__plug-image {
    background: white;
    width: 100%;
    object-fit: cover;
  }
}
</style>
