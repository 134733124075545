import Vuex from 'vuex'
import Vue from 'vue'
import * as businessEventApi from '@/services/api/businessEvent'
import { getLinkByEvent } from '@/utils/mediaHelpers'
import defaultSettings from '@/constants/settings'
import { getCurrentEventStaticData, getThemePath } from '@/utils/common'

import s3images from '@/constants/s3images'

Vue.use(Vuex)

const defaultState = () => ({
  event: null,
  languages: null,
  settings: null,
  eventDays: null,
  eventsList: null,
  defaultLanguage: null,
  translatedContent: [],
  currentStaticEvent: null
})

export default new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',

  state: defaultState(),

  getters: {
    deserializeEvent: (state, getters) => (data) => {
      const languages = data.languages?.map(el => getters.deserializeLanguage(el))
      const enLangId = languages.find(el => el.code === 'en')?.id
      const defaultLanguageId = data.default_language_id || enLangId || languages[0].id
      const defaultLanguage = languages.find(el => el.id === defaultLanguageId)

      return ({
        id: data.id,
        title: data.title,
        isActive: data.is_active,
        isStarted: data.is_started,
        settings: data.settings,
        eventDays: data.event_days?.map(el => getters.deserializeEventDay(el)),
        defaultLanguage,
        languages
      })
    },

    deserializeEventDay: (state, getters) => (data) => ({
      id: data.id,
      eventId: data.event_id,
      index: data.day + 1,
      isBroadcastStart: data.is_broadcast_start,
      isDayStart: data.is_day_start,
      broadcast: data.broadcast.map(el => getters.deserializeBroadcast(el))
    }),

    deserializeBroadcast: () => (data) => ({
      id: data.id,
      languageId: data.language_id,
      streamLink: data.stream_link,
      additionalStreamLink: data.additional_stream_link,
      plugType: data.plug_type,
      isPlug: data.is_plug,
      plugLink: data.plug_link
    }),

    deserializeLanguage: () => (data) => ({
      id: data.id,
      code: data.code,
      title: data.title
    }),

    deserializeEventFromEventsList: () => (data) => ({
      id: data.id,
      title: data.title,
      isActive: data.is_active,
      isStarted: data.is_started
    }),

    serializeEvent: (state, getters) => (data) => ({
      id: data.id,
      title: data.title,
      is_active: data.isActive,
      is_started: data.isStarted,
      event_days: data.eventDays?.map(el => getters.serializeEventDay(el)),
      languages: data.languages?.map(el => getters.serializeLanguage(el))
    }),

    serializeEventDay: (state, getters) => (data) => ({
      id: data.id,
      event_id: data.eventId,
      day: data.index - 1,
      is_broadcast_start: data.isBroadcastStart,
      is_day_start: data.isDayStart,
      broadcast: data.broadcast.map(el => getters.serializeBroadcast(el))
    }),

    serializeBroadcast: () => (data) => ({
      id: data.id,
      language_id: data.languageId,
      stream_link: data.streamLink,
      additional_stream_link: data.additionalStreamLink,
      plug_type: data.plugType,
      is_plug: data.isPlug,
      plug_link: data.plugLink
    }),

    serializeLanguage: () => (data) => ({
      id: data.id,
      code: data.code,
      title: data.title
    }),

    serializeEventFromEventsList: () => (data) => ({
      id: data.id,
      title: data.title,
      is_active: data.isActive,
      is_started: data.isStarted
    })
  },

  actions: {
    async getActiveEvent ({ commit, getters }) {
      const { data: eventData } = await businessEventApi.getActiveEvent()
      const { data: translatedContent } = await businessEventApi.getTranslatedContentMap()

      window.eventDataID = eventData.id

      commit('setEvent', getters.deserializeEvent(eventData))
      commit('setTranslatedContent', translatedContent)
      commit('setStaticEvent', getCurrentEventStaticData(eventData.id))
    },

    async getEventsList ({ commit, getters }) {
      const { data } = await businessEventApi.getEvents()
      commit('setEventsList', data.map(el => getters.deserializeEventFromEventsList(el)))
    },

    async createEvent ({ commit, getters }, title) {
      const { data } = await businessEventApi.createEvent(title)
      commit('addEventToList', getters.deserializeEvent(data))
    },

    async updateEvent ({ commit, getters }, payload) {
      const { data } = await businessEventApi.updateEvent(getters.serializeEvent(payload))
      commit('setEvent', getters.deserializeEvent(data))
    },

    async updateEventDay ({ commit, getters }, payload) {
      const serializedDay = getters.serializeEventDay(payload)
      const { data } = await businessEventApi.updateEventDay(serializedDay)
      const newSerializedDay = {
        ...serializedDay,
        ...data
      }
      commit('updateEventDay', getters.deserializeEventDay(newSerializedDay))
    }
  },

  mutations: {
    setStaticEvent (state, data) {
      state.currentStaticEvent = data
    },
    setEvent (state, { languages, eventDays, settings, defaultLanguage, ...event }) {
      state.event = event
      state.defaultLanguage = defaultLanguage
      state.languages = languages
      state.eventDays = eventDays
      state.settings = settings.data || defaultSettings.settings

      if (!settings.data) {
        return defaultSettings
      } else {
        const normalizeStyles = state.settings?.map(e => {
          return e.styles.map(a => {
            return {
              type: a.type,
              data: a.data,
              name: `--${e.name.split(' ').join('-')}-${a.name.split(' ').join('-')}`
            }
          })
        }) ?? []

        normalizeStyles.forEach(e => {
          e.forEach(style => {
            if (style.type === 'font') {
            // font-family
              const font = new FontFace(`${style.data.family}`, `url('${getLinkByEvent(`assets/fonts/${style.data.family}-Regular.woff2`)}')`)
              font.load().then((loaded) => document.fonts.add(loaded)).catch(e => e)
              // font-style and weight
              document.documentElement.style.setProperty(`${style.name}`,
            `${style.data.italic ? 'italic' : 'normal'}
             ${style.data.bold ? 'bold' : 'normal'}
             ${style.data.size}px
             ${style.data.family ? `${style.data.family}` : ''}`)
              document.documentElement.style.setProperty(`${style.name}-color`, `${style.data.color}`)
              if (style.data.underline) {
                document.documentElement.style.setProperty(`${style.name}-underline`, `${style.data.underline ? 'underline' : 'none'}`)
              }
            } else {
              document.documentElement.style.setProperty(`${style.name}`, `${style.data.color}`)
            }
          })
        })
      }

      const themePath = getThemePath(window.eventDataID)

      const getUrl = (e) => e.image.includes('assets/img/theme')
        ? require(`@/assets${e.image.replace('assets/img/theme', `/img/${themePath}`)}`)
        : require(`@/assets${e.image.replace('assets', '')}`)

      s3images.forEach(e => {
        document.documentElement.style.setProperty(e.name,
          `url('${getLinkByEvent(e.image)}'),
           url(${getUrl(e)})`
        )
      })
    },

    setTranslatedContent (state, payload) {
      const langEntries = Object.entries(payload)

      const langEntriesWithoutProtocol = langEntries.map(([lang, langContent]) => {
        const contentEntries = Object.entries(langContent)

        const contentEntriesWithoutProtocol = contentEntries.map(([key, value]) => {
          const valueWithoutProtocol = value.replace(/https?:/, '')
          return [key, valueWithoutProtocol]
        })

        return [lang, Object.fromEntries(contentEntriesWithoutProtocol)]
      })

      state.translatedContent = Object.fromEntries(langEntriesWithoutProtocol)
    },

    setEventsList (state, payload) {
      state.eventsList = payload
    },

    addEventToList (state, payload) {
      state.eventsList.push(payload)
    },

    updateEventDay (state, payload) {
      const dayIndex = state.eventDays.findIndex(el => el.id === payload.id)
      state.eventDays.splice(dayIndex, 1, payload)
    }
  }
})
