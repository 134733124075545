import apiClient from '@/services/api/apiClient'
import { CHAT_PREFIX } from '@/constants/apiPrefixes'
import { RoomsListResponse, SerializedChatMessage, SerializedChatQuizMessage } from './types'

export const getRoomsList = async () => apiClient.get<RoomsListResponse>(`${CHAT_PREFIX}/rooms`)

export const getHistory = async (limit?: number, cursor?: string) => apiClient.get<{
  before: number
  messages: SerializedChatMessage[]
}>(
  `${CHAT_PREFIX}/messages`,
  {
    params: {
      limit,
      cursor
    }
  }
)

export const getSharedMessagesHistory = async (limit?: number, cursor?: string) => apiClient.get<{
  before: number
  messages: SerializedChatMessage[]
}>(
  `${CHAT_PREFIX}/shared_messages`,
  {
    params: {
      limit,
      cursor
    }
  }
)

export const sendMessage = async (message: { text: string }) => apiClient.post<SerializedChatMessage>(
  `${CHAT_PREFIX}/messages`,
  message
)

export const sendSharedMessage = async (message: { text: string }) => apiClient.post<SerializedChatMessage>(
  `${CHAT_PREFIX}/shared_messages`,
  message
)

export const updateMessage = async (message: { text: string, id: string }) => apiClient.patch<SerializedChatMessage>(
  `${CHAT_PREFIX}/messages/${message.id}`,
  message
)

export const deleteMessage = async (message: { text: string, id: string }) => apiClient.delete<SerializedChatMessage>(
  `${CHAT_PREFIX}/messages/${message.id}`
)

export const voteQuiz = async (quizId: string, answerId: string) =>
  apiClient.post<SerializedChatQuizMessage>(
    `${CHAT_PREFIX}/quizzes/${quizId}/answers/${answerId}/vote`
  )

export const sendEmoji = async (emojiName: string) =>
  apiClient.post<{emoji_name: string}>(`${CHAT_PREFIX}/emoji`, {
    emoji_name: emojiName
  })
