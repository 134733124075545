import userService from '@/services/user'
import * as membersAPI from '@/services/api/members'
import * as employeesAPI from '@/services/api/employees'

const getProfileAPIRequest = process.env.VUE_APP_AUTH_PATH === 'staff'
  ? employeesAPI.getEmployeeById
  : membersAPI.getUserById

const updateProfileAPIRequest = process.env.VUE_APP_AUTH_PATH === 'staff'
  ? employeesAPI.updateEmployee
  : membersAPI.updateUser

export const getProfile = async () => {
  const user = await userService.getUser()
  if (!user.id) throw new Error('user not exist')

  return getProfileAPIRequest(user.id)
}

export const updateProfile = (profile) => updateProfileAPIRequest(profile)
