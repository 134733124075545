import { SETTINGS_MAIN_KEY } from '@/constants/settings'
import businessEventService from '@/services/businessEvent'

export default async (to, from, next) => {
  const currentEvent = await businessEventService.getActiveEvent()
  const settings = await businessEventService.getSettings(SETTINGS_MAIN_KEY)

  if (settings.some(setting => setting.name === to.name && !setting.enabled)) {
    if (to.name !== 'home' && settings.find(el => el.name === 'home')?.enabled && currentEvent?.isStarted) {
      return next('/')
    }

    if (to.name === 'home' && settings.find(el => el.name === 'my-profile')?.enabled) {
      return next('/my-profile')
    }

    return next('/404')
  }

  return next()
}
