var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select",
    _vm._g(
      _vm._b(
        {
          staticClass: "header-lang-selector text-uppercase",
          attrs: {
            "menu-props": { contentClass: "header-lang-selector__content" },
            items: _vm.languages,
            value: _vm.currentLanguage,
            "background-color": "transparent",
            "item-text": function(item) {
              return item.code.toUpperCase()
            },
            "item-value": "code",
            color: "white",
            "hide-details": "",
            dense: "",
            solo: "",
            flat: "",
            dark: "",
            "append-icon": "mdi-chevron-down"
          }
        },
        "v-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }