<template>
  <header class="header" :class="{'hide' : isHideHeader}">
    <v-container fluid>
      <div class="header__inner" :class="{'header-light-theme' : isLightTheme}">
        <div class="header__logo">
          <a href="/" class="logo">
            <img
              :src="isLightTheme ? require(`@/assets/img/svg/logo_cc.svg`) : require(`@/assets/img/${themePath}/logo_cc.svg`)"
              alt=""
            >
          </a>
        </div>

        <v-spacer />

        <div v-if="settings && settings.eventDays && settings.eventDays.enabled" class="header__days">
          <div class="t-days">
            <router-link
              v-for="(day, index) in eventDays"
              :key="day.id"
              :to="`/page/day/${day.index}`"
              class="t-day"
              active-class="active"
              :class="{
                't-day--left': !index,
                't-day--middle': index > 0 && index < eventDays.length - 1,
                't-day--right': eventDays.length && (eventDays.length - 1) === index,
              }"
            >
              <span class="t-day__text text-uppercase">{{ $t('day') }} {{ day.index }}</span>

              <div v-if="day.isDayStart" class="t-day-live">
                <img
                  :src="require('@/assets/img/svg/live.svg')"
                  alt=""
                >
              </div>
            </router-link>
          </div>
        </div>

        <v-spacer />

        <div class="header__nav">
          <nav class="topmenu">
            <ul>
              <li v-if="settings && settings.schedule && settings.schedule.enabled">
                <router-link to="/schedule">
                  {{ $t('schedule') }}
                </router-link>
              </li>

              <li v-if="settings && settings.nominations && settings.nominations.enabled">
                <router-link to="/nominations">
                  {{ $t('new ranks') }}
                </router-link>
              </li>

              <li
                v-if="settings && settings.help && settings.help.enabled"
                class="topmenu__help-link"
                @click="helpModal = true"
              >
                {{ $t('help') }}
              </li>
            </ul>
          </nav>
        </div>

        <v-spacer />

        <div class="header__right">
          <div class="header__right-user">
            <div v-if="settings && settings['my-profile'].enabled" class="user-panel">
              <router-link to="/my-profile" class="usp-link">
                <div class="usp-link__img">
                  <Avatar
                    small
                    tile
                    :src="user.avatar && user.avatar.url"
                    :alt="`avatar ${user.lastName}`"
                  />
                </div>

                <div class="usp-link__text-block">
                  <div class="usp-link__text text-uppercase">
                    {{ user.firstName }} {{ user.lastName && user.lastName[0] }}.
                  </div>
                </div>

                <div class="usp-points">
                  137 корал-фишек
                </div>
              </router-link>
            </div>

            <div class="user-logout cursor-pointer" @click="openLogoutDialog">
              <img
                :src="isLightTheme ? require(`@/assets/img/svg/logout.svg`) : require(`@/assets/img/${themePath}/logout.svg`)"
                :title="$t('logout')"
                alt=""
              >
            </div>
          </div>

          <div class="header__right-dropdown">
            <LangSelector
              key="langSelector"
              v-model="language"
              :languages="languages"
            />
          </div>

          <div class="header__right-days">
            <div class="topline-days-panel">
              <a
                v-click-outside="hideDaysDropdown"
                class="current-day text-uppercase cursor-pointer"
                :class="{'current-day--light' : isMenuOpened}"
                @click.prevent="toggleDays"
              >
                {{ $t('day') }}
              </a>

              <div class="topline-days-dropdown" :class="{'active': isDaysOpened}">
                <router-link
                  v-for="day in eventDays"
                  :key="day.id"
                  :to="`/page/day/${day.index}`"
                  class="day-link text-uppercase cursor-pointer"
                >
                  {{ $t('day') }} {{ day.index }}
                </router-link>
              </div>
            </div>
          </div>

          <div class="header__right-menu">
            <div class="mobile-menu-block">
              <a
                class="mobile-menu-btn"
                :class="{active: isMenuOpened}"
                href="#"
                @click.prevent="isMenuOpened = !isMenuOpened"
              >
                <span />
              </a>

              <div :class="{active: isMenuOpened}" class="mobile-menu-panel-container">
                <v-expand-transition>
                  <div v-show="isMenuOpened" class="mobile-menu-panel">
                    <div class="mobile-menu__header">
                      <div class="header__inner">
                        <div class="header__logo">
                          <router-link to="/" class="logo">
                            <img
                              :src="isMenuOpened ? require(`@/assets/img/theme/logo_cc.svg`) : require(`@/assets/img/${themePath}/logo_cc.svg`)"
                              alt=""
                            >
                          </router-link>
                        </div>

                        <v-spacer />

                        <div class="header__days">
                          <div class="t-days">
                            <router-link
                              v-for="(day, index) in eventDays"
                              :key="day.id"
                              :to="`/page/day/${day.index}`"
                              class="t-day"
                              :class="{
                                't-day--left': !index,
                                't-day--right': eventDays.length && (eventDays.length-1) === index,
                                'active': day.isDayStart
                              }"
                            >
                              <span class="t-day__text text-uppercase">{{ $t('day') }} {{ day.index }}</span>

                              <div class="t-day-live">
                                <img
                                  :src="require('@/assets/img/svg/live.svg')"
                                  alt=""
                                >
                              </div>

                              <span class="fm-live t-day-fm-live">
                                <img
                                  :src="require('@/assets/img/svg/live.svg')"
                                  alt=""
                                >
                              </span>
                            </router-link>
                          </div>
                        </div>

                        <v-spacer />

                        <div class="header__nav">
                          <nav class="topmenu">
                            <ul>
                              <li>
                                <router-link to="/schedule">
                                  {{ $t('schedule') }}
                                </router-link>
                              </li>

                              <li>
                                <router-link to="/nominations">
                                  {{ $t('new ranks') }}
                                </router-link>
                              </li>

                              <li @click="helpModal = true">
                                {{ $t('help') }}
                              </li>
                            </ul>
                          </nav>
                        </div>

                        <v-spacer />

                        <div class="header__right">
                          <div class="header__right-user">
                            <div class="user-panel">
                              <router-link to="/my-profile" class="usp-link">
                                <div class="usp-link__img">
                                  <Avatar
                                    tile
                                    :src="user.avatar && user.avatar.url"
                                    :alt="`avatar ${user.lastName}`"
                                  />
                                </div>

                                <div class="usp-link__text-block">
                                  <div class="usp-link__text text-uppercase">
                                    {{ user.firstName }} {{ user.lastName && user.lastName[0] }}.
                                  </div>
                                </div>

                                <div class="usp-points">
                                  137 корал-фишек
                                </div>
                              </router-link>
                            </div>

                            <div class="user-logout cursor-pointer" @click="openLogoutDialog">
                              <img
                                :src="require(`@/assets/img/${themePath}/logout.svg`)"
                                :title="$t('logout')"
                                alt="logout"
                              >
                            </div>
                          </div>

                          <div class="header__right-dropdown">
                            <LangSelector
                              key="langSelector"
                              v-model="language"
                              :languages="languages"
                            />
                          </div>

                          <div class="header__right-days">
                            <div class="topline-days-panel">
                              <a
                                class="current-day text-uppercase cursor-pointer"
                                @click.prevent="toggleDays"
                              >
                                {{ $t('day') }}
                              </a>

                              <div class="topline-days-dropdown" :class="{'active': isDaysOpened}">
                                <router-link
                                  v-for="day in eventDays"
                                  :key="day.id"
                                  :to="`/page/day/${day.index}`"
                                  class="day-link text-uppercase cursor-pointer"
                                >
                                  {{ $t('day') }} {{ day.index }}
                                </router-link>
                              </div>
                            </div>
                          </div>

                          <div class="header__right-menu">
                            <div class="mobile-menu-block">
                              <a
                                class="mobile-menu-btn"
                                :class="{active: isMenuOpened}"
                                href="#"
                                @click.prevent="isMenuOpened = !isMenuOpened"
                              >
                                <span />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mobile-menu-forum-logo-block">
                      <div class="mobile-menu-forum-logo">
                        <c-img
                          src="assets/img/theme/event-logo-light.png"
                          alt=""
                        />
                      </div>
                    </div>

                    <nav class="mobile-menu">
                      <ul>
                        <li>
                          <router-link to="/schedule" class="text-uppercase">
                            {{ $t('schedule') }}
                          </router-link>
                        </li>

                        <li>
                          <router-link to="/nominations" class="text-uppercase">
                            {{ $t('new ranks') }}
                          </router-link>
                        </li>
                      </ul>
                    </nav>

                    <div class="mobile-menu-actions">
                      <div class="mobile-menu-help">
                        <div class="text-uppercase cursor-pointer" @click="helpModal = true">
                          {{ $t('help') }}
                        </div>
                      </div>

                      <div class="user-logout cursor-pointer" @click="openLogoutDialog">
                        <img
                          :src="require(`@/assets/img/theme/logout.svg`)"
                          :title="$t('logout')"
                          alt=""
                        >
                      </div>

                      <div class="mobile-menu-lang-block">
                        <LangSelector
                          key="langSelector"
                          v-model="language"
                          :languages="languages"
                        />
                      </div>
                    </div>

                    <div class="mm-tr-text-block">
                      <div class="mm-tr-text">
                        <span v-if="activeEvent">{{ activeEvent.title }}</span>
                      </div>
                    </div>

                    <div class="mm-account-link-block">
                      <router-link to="/my-profile" class="mm-account-link">
                        <div class="mm-account-link__img">
                          <Avatar
                            tile
                            :src="user.avatar && user.avatar.url"
                            :alt="`avatar ${user.lastName}`"
                          />
                        </div>

                        <div class="mm-account-link__name">
                          {{ user.firstName }} {{ user.lastName && user.lastName[0] }}.
                        </div>
                      </router-link>
                    </div>
                  </div>
                </v-expand-transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>

    <modal
      v-model="open"
      logo
      btn-left="logout"
      btn-rigth="cancel"
      @btnRigthClick="closeModal"
      @btnLeftClick="actionLogout"
      @onCloseModal="open = false"
    >
      <template slot="content">
        {{ $t('are you sure want to logout') }}
      </template>
    </modal>

    <modal
      v-model="helpModal"
      :max-height="400"
      logo
      @onCloseModal="helpModal = false"
    >
      <template slot="content">
        <HelpHTML />
      </template>
    </modal>
  </header>
</template>

<script>
import HelpHTML from '@/components/Help'
import Modal from '@/components/Modal'
import languageService from '@/services/language'
import businessEventService from '@/services/businessEvent'
import LangSelector from '@/modules/LayoutMain/components/header/LangSelector'
import EventBus from '@/EventBus'
import Avatar from '@/components/Avatar'
import { SETTINGS_HEADER_FOOTER_KEY } from '@/constants/settings'
import { getThemePath } from '@/utils/common'

export default {
  components: {
    LangSelector,
    Modal,
    HelpHTML,
    Avatar
  },

  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    isLightTheme: {
      type: Boolean,
      default: false
    },
    isHideHeader: {
      type: Boolean,
      default: false
    }
  },

  data: () => {
    return {
      activeEvent: null,
      helpModal: false,
      open: false,
      language: null,
      languages: [],
      isDaysOpened: false,
      isMenuOpened: false,
      eventDays: [],
      settings: null
    }
  },

  computed: {
    themePath () {
      return getThemePath()
    }
  },

  watch: {
    language (val) {
      languageService.updateLanguageByCode(val)
    },

    isMenuOpened: function (isOpened) {
      // eslint-disable-next-line no-console
      if (isOpened && this.$vuetify.breakpoint.name === 'xs') {
        document.documentElement.classList.add('html-menu-overflow')
      } else {
        document.documentElement.classList.remove('html-menu-overflow')
      }
    },

    $route () {
      this.isMenuOpened = false
    }
  },

  async created () {
    this.activeEvent = await businessEventService.getActiveEvent()
    this.setDocumentTitle()
    this.languages = await businessEventService.getEventLanguages()
    this.language = await languageService.getLanguageCode()

    this.eventDays = await businessEventService.getEventDays()
    this.normalizeSettings(await businessEventService.getSettings(SETTINGS_HEADER_FOOTER_KEY))

    EventBus.subscribe('businessEvent:update', this.onEventUpdate)
  },

  beforeDestroy () {
    EventBus.unsubscribe('businessEvent:update', this.onEventUpdate)
  },

  methods: {
    openLogoutDialog () {
      this.open = true
      EventBus.emit('action:openLogoutDialog')
    },
    setDocumentTitle () {
      document.title = this.activeEvent.title
    },
    normalizeSettings (obj) {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const tempKey = obj[key].name
          if (tempKey !== key) {
            const temp = obj[key]
            delete obj[key]
            obj[tempKey] = temp
          }
        }
      }
      this.settings = { ...obj }
    },
    async onEventUpdate () {
      this.eventDays = await businessEventService.getEventDays()
    },

    hideDaysDropdown () {
      if (this.$vuetify.breakpoint.name !== 'xs') {
        this.isDaysOpened = false
      }
    },

    toggleDays () {
      this.isDaysOpened = !this.isDaysOpened
      if (this.$vuetify.breakpoint.name !== 'xs') {
        this.isMenuOpened = false
      }
    },

    actionLogout () {
      EventBus.emit('action:logout')
    },

    closeModal () {
      this.open = false
    }
  }
}
</script>

<style lang="scss">
  @import "./styles";
</style>
