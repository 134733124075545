var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "footer", class: { "footer-light": _vm.isLightTheme } },
    [
      _c("v-container", [
        _c("div", { staticClass: "footer__inner" }, [
          _c("div", { staticClass: "footer__left" }, [
            _c(
              "div",
              { staticClass: "footer__left-logo" },
              [
                _c("c-img", {
                  attrs: {
                    src: "assets/img/" + _vm.themePath + "/event-logo.png",
                    alt: ""
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "footer__content" }, [
            _c(
              "nav",
              { staticClass: "footer-menu" },
              [
                _c(
                  "v-row",
                  [
                    _vm.settings &&
                    _vm.settings.eventDays &&
                    _vm.settings.eventDays.enabled
                      ? _c(
                          "v-col",
                          { staticClass: "footer-menu__column" },
                          _vm._l(_vm.eventDays, function(day) {
                            return _c(
                              "div",
                              { key: day.id, staticClass: "footer-menu__item" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "footer-menu__item-link",
                                    class: {
                                      "fm-link--live": day.isDayStart
                                    },
                                    attrs: { to: "/page/day/" + day.index }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("upperFirst")(_vm.$t("day"))
                                        ) +
                                        " " +
                                        _vm._s(day.index) +
                                        " "
                                    ),
                                    day.isDayStart
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "fm-live footer__fm-live"
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/svg/live.svg"),
                                                alt: ""
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _c("v-col", [
                      _vm.settings &&
                      _vm.settings.schedule &&
                      _vm.settings.schedule.enabled
                        ? _c(
                            "div",
                            { staticClass: "footer-menu__item" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "footer-menu__item-link",
                                  attrs: { to: "/schedule" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("upperFirst")(_vm.$t("schedule"))
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.settings &&
                      _vm.settings.nominations &&
                      _vm.settings.nominations.enabled
                        ? _c(
                            "div",
                            { staticClass: "footer-menu__item" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "footer-menu__item-link",
                                  attrs: { to: "/nominations" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("upperFirst")(
                                          _vm.$t("new ranks")
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c("v-col", [
                      _vm.settings &&
                      _vm.settings["my-profile"] &&
                      _vm.settings["my-profile"].enabled
                        ? _c(
                            "div",
                            { staticClass: "footer-menu__item" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "footer-menu__item-link",
                                  attrs: { to: "/my-profile" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("upperFirst")(
                                          _vm.$t("personal area")
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.settings &&
                      _vm.settings.help &&
                      _vm.settings.help.enabled
                        ? _c("div", { staticClass: "footer-menu__item" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "footer-menu__item-link footer-menu__item-link--help",
                                staticStyle: {
                                  cursor: "pointer",
                                  transition: "0.4s ease"
                                },
                                on: { click: _vm.handleClickHelp }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("upperFirst")(_vm.$t("help"))
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "footer__right" }, [
            _c("a", { staticClass: "footer-logo", attrs: { href: "#" } }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/coral_logo.svg"),
                  alt: ""
                }
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "footer__inner__text" }, [
          _c("p", [
            _vm._v(" Все права запрещены "),
            _c("br"),
            _vm._v(" (С) Coral Club 2022 ")
          ])
        ])
      ]),
      _c(
        "modal",
        {
          attrs: { "max-height": 400, logo: "" },
          on: {
            onCloseModal: function($event) {
              _vm.helpModal = false
            }
          },
          model: {
            value: _vm.helpModal,
            callback: function($$v) {
              _vm.helpModal = $$v
            },
            expression: "helpModal"
          }
        },
        [_c("template", { slot: "content" }, [_c("HelpHTML")], 1)],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }