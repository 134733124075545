const serverErrors = {
  'invalid password or username': '@:message.errors.invalidLogin',
  invalidLogin: 'Neplatné heslo nebo uživatelské jméno',
  'internal server error': 'Chyba interního serveru',
  'not found error': 'Nalézt'
}

const validationErrors = {
  NOT_UNIQUE: 'Pole se stejnou hodnotou již existuje',
  REQUIRED: 'Požadované pole',
  EMAIL: 'Neplatný e-mail',
  PHONE: 'Neplatný telefon',
  UNIVERSAL_INVALID_FIELD: 'Neplatná hodnota',
  CYRILLIC_AND_SPACE: 'Pouze cyrilice a mezery',
  DIGIT: 'Pouze číslice',
  ALPHANUMERIC: 'Pouze čísla a písmena',
  RANGE_MIN: 'Pole musí obsahovat alespoň znaky {min}',
  RANGE_MAX: 'Pole nesmí obsahovat více než znaky {max}',
  RANGE_MIN_MAX: 'Pole musí obsahovat znaky {min} až {max}',
  RANGE_EXACT: 'Pole musí obsahovat znaky: {count}',
  LETTERS_RANGE_MIN: 'Pole musí obsahovat alespoň {min} písmena',
  LETTERS_RANGE_MAX: 'Pole nesmí obsahovat více než písmena {max}',
  LETTERS_RANGE_MIN_MAX: 'Pole musí obsahovat písmena {min} až {max}',
  LETTERS_RANGE_EXACT: 'Pole musí obsahovat písmena: {count}',
  FILE_SIZE_MAX: 'Velikost souboru by měla být menší než {max} MB!',
  WORDS_COUNT: 'Nesprávný počet slov. Očekává se: {wordsCount}',
  FILE_FORMAT_TYPES: 'Dostupné formáty: .pdf, .GIF, .jpg',
  MAJORITY: 'Pouze pro dospělé',
  DIGITS_AND_LATIN_CAPS_LETTERS: 'Pouze čísla a velká latinská písmena',
  INVALID_DATE: 'Neplatné datum'
}

export default {
  default: 'Něco se pokazilo',
  'user has no roles': 'uživatel nemá žádné role',
  ...serverErrors,
  validate: validationErrors
}
