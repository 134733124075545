import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import './plugins/global-ui'
import './plugins/notifications'
import './plugins/tour'
import './plugins/cookies'
import './plugins/errorHandlers'
import './plugins/vueFilters'
import './styles/index.scss'

Vue.config.productionTip = false

if (process.env.NODE_ENV !== 'production') {
  console.info(process.env)
} else {
  // Hide sensitive keys.
  console.info({ NODE_ENV: process.env.NODE_ENV })
}

Vue.use(Vuex)

new Vue({
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
