import languageService from '@/services/language'
import businessService from '@/services/businessEvent'
import axios from 'axios'
import apiClient from './apiClient'
import { MEMBER_PREFIX } from '@/constants/apiPrefixes'

const getLangCode = async () => languageService.getLanguageCode()
const getLangId = async () => languageService.getLanguage().then(res => res.id)
const getContentLink = async (path) => {
  const translatedContentMap = await businessService.getTranslatedContentMap()
  const langCode = await getLangCode()
  return translatedContentMap[langCode][path]
}

export const getMainTranslationContent = async () => axios.get(await getContentLink('general'))

export const getMaterials = async () => axios.get(await getContentLink('materiali'))

export const getNominees = async () => apiClient.get(`${MEMBER_PREFIX}/nominees`, {
  params: {
    event_id: await businessService.getActiveEvent().then(res => res.id)
  }
})

export const getNomineesGroupedByStatus = async () => apiClient.get(`${MEMBER_PREFIX}/statuses`, {
  params: {
    event_id: await businessService.getActiveEvent().then(res => res.id)
  }
})

export const getNomineeBySlug = async (slug) => apiClient.get(`${MEMBER_PREFIX}/nominees/${slug}`)

export const getSchedules = async () => axios.get(await getContentLink('schedules'))

export const getTests = async () => axios.get(await getContentLink('test_with_quest'))

export const getSpeakers = async ({ onMain } = {}) => apiClient.get(
  `${MEMBER_PREFIX}/${await getLangId()}/speakers`,
  {
    params: {
      event_id: await businessService.getActiveEvent().then(res => res.id),
      on_main: onMain
    }
  }
)

export const getSpeakerBySlug = async (slug) => apiClient.get(`${MEMBER_PREFIX}/${await getLangId()}/speakers/${slug}`)
